import SuspenseLoader from '@components/shared/suspense-loader/SuspenseLoader.tsx';
import { UrlParams } from '@shared/models/generic';
import { DocTypeSummary } from '@shared/models/inbox';
import documentSlice from '@shared/store/documentSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document.module.scss';
import { ReactComponent as LockedDocIcon } from '@svg/locked-doc.svg';
import { ReactComponent as NotFoundDocIcon } from '@svg/not-found-doc.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DocumentHeader from './header/DocumentHeader';
import DocumentLabeler from './labeler/DocumentLabeler';

interface Props {
  routeDocType: DocTypeSummary;
  handleNext: (mustReplace?: boolean) => void;
  handlePrevious: (mustReplace?: boolean) => void;
  historical?: boolean;
}

const Document: React.FC<Props> = ({ routeDocType, handleNext, handlePrevious, historical }) => {
  const nextDocumentId = useSelector((state) => state.document.nextDocumentId);
  const copySub = useSelector((state) => state.subs.copySub);
  const documentSub = useSelector((state) => state.subs.documentSub);
  const userInboxes = useSelector((state) => state.user.inboxes);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const copyStructure = useSelector((state) => state.document.copyStructure);
  const doesNotExist = useSelector((state) => state.document.doesNotExist);
  const { autoAdvance } = useSelector((state) => state.inbox.currentInbox.settings);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { inboxId, docId }: UrlParams = useParams();
  const { t } = useTranslation();

  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [activeInboxName, setActiveInboxName] = useState(
    userInboxes.find((i) => i.id === inboxId) ? userInboxes.find((i) => i.id === inboxId).settings.name : null
  );

  const actionColorMap = {
    approve: '#0085FF',
    delete: 'red',
    bounce: 'orange',
  };

  useEffect(() => {
    if (userInboxes && userInboxes.length > 0) {
      const inbox = userInboxes.find((i) => i.id === inboxId);
      if (!inbox) {
        setIsUnauthorized(true);
      } else {
        setActiveInboxName(inbox.settings.name);
      }
    }
  }, [inboxId, userInboxes]);

  useEffect(() => {
    if (historical) return;
    let shouldGoNext = false;
    if (activeDocument && !activeDocument.active && !activeDocument.parentDocId) {
      shouldGoNext = true;
    }
    if (
      activeDocument &&
      activeDocument.active === false &&
      copyStructure?.originalDoc &&
      copyStructure?.originalDoc?.active === false
    ) {
      shouldGoNext = true;
    }
    if (shouldGoNext) {
      const timestamp = activeDocument.action.timestamp;
      // if the document was approved less that 20s ago, go next.
      // This is to handle the case where a user goes to the next document and another user just approved it (race condition)

      if (timestamp) {
        const diff = Date.now() - timestamp;
        if (diff < 20 * 1000) {
          if (copySub) copySub();
          if (documentSub) documentSub();
          if (nextDocumentId && autoAdvance) {
            dispatch(documentSlice.actions.setCopyStructure(null));
            dispatch(documentSlice.actions.setActiveDocument(null));
            handleNext(true);
          } else {
            dispatch(documentSlice.actions.setActiveDocument(null));
            dispatch(documentSlice.actions.setCopyStructure(null));
            navigate(`/inbox/${inboxId}`);
          }
        } else {
          // redirect to archive instead.
          navigate(`/inbox/${inboxId}/historical/${docId}`);
        }
      }
    }
  }, [
    historical,
    autoAdvance,
    navigate,
    copySub,
    activeDocument,
    copyStructure,
    handleNext,
    nextDocumentId,
    docId,
    inboxId,
    dispatch,
    documentSub,
  ]);

  if (!userInboxes || userInboxes.length === 0) return <SuspenseLoader fullPage name="Document" />;
  if (isUnauthorized) {
    return (
      <div className={s.container__unauthorized}>
        <div className={s.inner}>
          <LockedDocIcon />
          <h2>{t('document:unauthorized.title')}</h2>
          <span>{t('document:unauthorized.description')}</span>
          <Link to={'/'}>{t('document:unauthorized.return')}</Link>
        </div>
      </div>
    );
  }
  if (doesNotExist) {
    return (
      <div className={s.container__unauthorized}>
        <div className={s.inner}>
          <NotFoundDocIcon />
          {doesNotExist.action ? (
            <>
              <h2>
                Document{' '}
                <span style={{ color: actionColorMap[doesNotExist.action.type] }}>
                  {t(`document:actions.${doesNotExist?.action.type}`)}
                </span>{' '}
                {t('document:notFound.by')} {doesNotExist.action.actorEmail ?? '?'}
              </h2>
              <span>
                {t('document:notFound.deletedOn')}: <br />{' '}
                {doesNotExist.latestWorkflowRun.timeProvisioned.toLocaleString()}
              </span>
            </>
          ) : (
            <>
              <h2>{t('document:notFound.title')}</h2>
              <span>{t('document:notFound.description')}</span>
            </>
          )}
          <Link to={'/'}>{t('document:notFound.return')}</Link>
        </div>
      </div>
    );
  }

  return (
    <div className={s.container}>
      <DocumentHeader
        activeDocument={activeDocument}
        routeDocType={routeDocType}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        activeInboxName={activeInboxName}
      />

      <DocumentLabeler handleNext={handleNext} handlePrevious={handlePrevious} />
    </div>
  );
};

export default Document;
