import StyledSelect from '@components/shared/dropdown/StyledSelect';
import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import { MasterDataMapping } from '@shared/models/document';
import se from '@shared/styles/component/admin/admin-masterdata.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as EyeIcon } from '@svg/eye.svg';
import { ReactComponent as IdIcon } from '@svg/id-icon.svg';
import { ReactComponent as LinkIcon } from '@svg/link-icon.svg';
import { ReactComponent as PinIcon } from '@svg/pin.svg';
import { ReactComponent as SearchIcon } from '@svg/search-icon.svg';
import { ReactComponent as TextIcon } from '@svg/tool-default-icon.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminCharSelector from './AdminCharSelector.tsx';

interface Props {
  mapping: MasterDataMapping;
  options: any[];
  groupedOptions: any[];
  handleChange: (updatedMapping: MasterDataMapping) => void;
  handleDeleteRow: () => void;
}

type MappingType = 'hidden' | 'name' | 'mapped';
type TypeType = 'id' | 'text';
const AdminMasterdataEditRow: React.FC<Props> = ({
  mapping,
  handleChange,
  options,
  groupedOptions,
  handleDeleteRow,
}) => {
  const [mappingType, setMappingType] = useState<MappingType>('hidden');
  const [type, setType] = useState<TypeType>(mapping.type ?? 'text');
  const [defaultValue, setDefaultValue] = useState();
  const { t } = useTranslation();
  const handleDropdownChange = useCallback(
    (e) => {
      const changed = { ...mapping };
      changed.reference = e.value;
      changed.referenceType = e.tag.value;
      handleChange(changed);
    },
    [handleChange, mapping]
  );
  const handleCheckbox = (key: string, state: boolean) => {
    const changed = { ...mapping };
    changed[key] = state;
    handleChange(changed);
  };
  const handleInput = (value: string) => {
    const changed = { ...mapping };
    changed.reference = null;
    changed.referenceType = null;
    changed.displayName = value;
    handleChange(changed);
  };
  useEffect(() => {
    if (options.length === 0) return;
    let def = options[0];

    if (mapping.reference) {
      const opt = options.find((o) => o.value === mapping.reference);
      if (opt) {
        def = opt;
      }
      setMappingType('mapped');
    } else if (mapping.displayName) {
      setMappingType('name');
    } else if (mappingType === 'mapped') {
      const opt = options[0];
      if (opt) {
        def = opt;
      }
      handleDropdownChange(def);
    }
    setDefaultValue(def);
  }, [handleDropdownChange, mappingType, mapping.displayName, mapping.reference, options]);
  return (
    <>
      <div style={{ color: '#898B99', opacity: mappingType === 'hidden' ? 0.5 : 1 }}>{mapping.id}</div>

      <div
        className={clsx(se.mapping_row_item, se.mapping_row_item__center)}
        style={{ maxWidth: 200, marginLeft: 'auto' }}
      >
        <div className={se.switcher}>
          <div
            data-testid={'masterdata-switcher'}
            onClick={() => {
              setMappingType('hidden');
              const changed = { ...mapping };
              changed.isSearchable = false;
              changed.displayName = null;
              changed.reference = null;
              changed.referenceType = null;
              handleChange(changed);
            }}
            className={clsx(se.switcher_option, {
              [se.switcher_option__active]: mappingType === 'hidden',
            })}
          >
            <EyeIcon />
          </div>
          <div
            data-testid={'masterdata-switcher'}
            onClick={() => {
              const changed = { ...mapping };
              changed.reference = null;
              changed.referenceType = null;
              handleChange(changed);
              setMappingType('name');
            }}
            className={clsx(se.switcher_option, {
              [se.switcher_option__active]: mappingType === 'name',
            })}
          >
            <TextIcon style={{ transform: 'scale(0.8)' }} />
          </div>
          <div
            data-testid={'masterdata-switcher'}
            onClick={() => {
              const changed = { ...mapping };
              changed.displayName = null;
              handleChange(changed);
              setMappingType('mapped');
            }}
            className={clsx(se.switcher_option, {
              [se.switcher_option__active]: mappingType === 'mapped',
            })}
          >
            <LinkIcon />
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          gap: 16,
          alignItems: 'center',
        }}
      >
        {mappingType === 'mapped' ? (
          <StyledSelect
            onChange={handleDropdownChange}
            defaultValue={defaultValue}
            options={groupedOptions}
            value={defaultValue}
          />
        ) : (
          <input
            required
            disabled={mappingType === 'hidden'}
            style={{ backgroundColor: 'white' }}
            value={mapping.displayName ?? ''}
            onChange={(e) => handleInput(e.target.value)}
            className={s.input}
            placeholder={'Enter a name'}
            type="text"
          />
        )}
      </div>
      <Tooltip
        position={'top'}
        tooltipStyle={{ maxWidth: 300 }}
        content={
          <div>
            <b>{t('admin:masterdata.headerChars')}</b> <p>{t('admin:masterdata.headerCharsTooltip')}</p>
          </div>
        }
      >
        <div className={se.switcher} style={{ padding: '0 12px' }}>
          <AdminCharSelector
            charList={mapping?.charsToFilter ?? []}
            onChange={(chars) => {
              handleChange({
                ...mapping,
                charsToFilter: chars,
              });
            }}
          />
        </div>
      </Tooltip>
      <Tooltip
        position={'top'}
        tooltipStyle={{ maxWidth: 300 }}
        content={
          <div>
            <b>{t('admin:masterdata.headerType')}</b> <p>{t('admin:masterdata.headerTypeTooltip')}</p>
          </div>
        }
      >
        <div className={clsx(se.mapping_row_item, se.mapping_row_item__center)}>
          <div className={se.switcher}>
            <div
              onClick={() => {
                const changed = { ...mapping };
                changed['type'] = 'id';
                if (mapping['charsToFilter']?.length === 0 || !mapping['charsToFilter']) {
                  changed['charsToFilter'] = [
                    '.',
                    ',',
                    ':',
                    '/',
                    '[',
                    ']',
                    '(',
                    ')',
                    '{',
                    '}',
                    ' ',
                    '_',
                    '-',
                    '*',
                    '+',
                    "'",
                  ];
                }

                handleChange(changed);
                setType('id');
              }}
              className={clsx(se.switcher_option, {
                [se.switcher_option__active]: type === 'id',
              })}
            >
              <IdIcon />
            </div>
            <div
              onClick={() => {
                const changed = { ...mapping };
                changed['type'] = 'text';
                handleChange(changed);
                setType('text');
              }}
              className={clsx(se.switcher_option, {
                [se.switcher_option__active]: type === 'text',
              })}
            >
              <TextIcon style={{ transform: 'scale(0.8)' }} />
            </div>
          </div>
        </div>
      </Tooltip>
      <Tooltip
        position={'top'}
        tooltipStyle={{ maxWidth: 300 }}
        content={
          <div>
            <b>{t('admin:masterdata.headerPin')}</b> <p>{t('admin:masterdata.headerPinTooltip')}</p>
          </div>
        }
      >
        <div className={se.switcher}>
          <button
            type={'button'}
            disabled={mappingType === 'hidden'}
            onClick={() => {
              handleCheckbox('isPinned', !(mapping.isPinned ?? false));
            }}
            className={clsx(se.switcher_option, {
              [se.switcher_option__active]: mapping.isPinned ?? false,
            })}
          >
            <PinIcon style={{ transform: 'scale(1)' }} />
          </button>
        </div>
      </Tooltip>
      <Tooltip
        position={'top'}
        tooltipStyle={{ maxWidth: 300 }}
        content={
          <div>
            <b>{t('admin:masterdata.headerSearchable')}</b>{' '}
            <p>{t('admin:masterdata.headerSearchableTooltip')}</p>
          </div>
        }
      >
        <div className={se.switcher}>
          <button
            data-testid={'masterdata-searchable'}
            type={'button'}
            disabled={mappingType === 'hidden'}
            onClick={() => {
              handleCheckbox('isSearchable', !(mapping.isSearchable ?? false));
            }}
            className={clsx(se.switcher_option, {
              [se.switcher_option__active]: mapping.isSearchable ?? false,
            })}
          >
            <SearchIcon style={{ transform: 'scale(1.1)' }} />
          </button>
        </div>
      </Tooltip>

      <div className={se.switcher} style={{ marginLeft: 10 }}>
        <button
          data-testid={'masterdata-delete'}
          type={'button'}
          onClick={handleDeleteRow}
          className={clsx(se.switcher_option, se.switcher_option__delete)}
        >
          <TrashIcon />
        </button>
      </div>
    </>
  );
};

export default AdminMasterdataEditRow;
