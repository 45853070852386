import AdminInputDialog from '@components/admin/components/AdminInputDialog.tsx';
import AdminMultiSelectDialog, {
  MultiSelectDetailsType,
} from '@components/admin/components/AdminMultiSelectDialog.tsx';
import AdminSortableFieldOptions from '@components/admin/components/AdminSortableFieldOptions.tsx';
import AdminTableItemRow from '@components/admin/components/AdminTableItemRow.tsx';
import FormBodyHeader from '@components/admin/components/form/FormBodyHeader.tsx';
import { FormHeaderNav } from '@components/admin/components/form/FormHeaderNav.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';

import { fieldTypeClientToRaw, IClientFieldType } from '@shared/helpers/converters/fieldtype.ts';
import { compareObjects, convertToId } from '@shared/helpers/helpers.ts';
import useChangeTracker from '@shared/hooks/useChangeTracker.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import { adminSlice, deleteEntityType, patchEntityType, postEntityType } from '@shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {}
const AdminInboxesFieldEdit: React.FC<Props> = () => {
  const activeEntityTypeList = useSelector((state) => state.admin.activeEntityTypeList);
  const inboxEntityTypes = useSelector((state) => state.admin.inboxEntityTypes);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const { fieldId, inboxId } = useParams();
  const navigate = useNavigate();

  const entityTypeTypes = [
    { value: 'boolean', label: 'Boolean' },
    { value: 'text', label: 'Text' },
    { value: 'image', label: 'Image' },
    { value: 'table', label: 'Table' },
    { value: 'complex', label: 'Complex' },
    { value: 'options', label: 'Options' },
  ];

  const filteredInboxEntityTypes = useMemo(() => {
    return inboxEntityTypes?.filter((type) => type.type !== 'table' && type.type !== 'complex');
  }, [inboxEntityTypes]);

  const activeEntityType = useMemo(() => {
    return inboxEntityTypes?.find((id) => id.id === fieldId);
  }, [fieldId, inboxEntityTypes]);

  const handleInput = (value: any, field: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      const fields = field.split('.');
      fields.reduce((acc, cur, i) => (acc[cur] = fields.length - 1 === i ? value : acc[cur] || {}), copy);
      return copy;
    });
  };

  const handleSaveModal = (e) => {
    e.preventDefault();
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          save(null);
          navigate(`/admin/inboxes/${inboxId}/fields`);
        }}
        cancelAction={() => null}
        confirmText={t('document:dialog.save')}
        cancelText={t('document:dialog.cancel')}
        dialogType={'confirmation'}
        title={t('admin:multiSave.title')}
        text={t('admin:multiSave.description', { number: activeEntityTypeList.length })}
      />
    );
  };

  const handleSave = async () => {
    const mappedState = { ...state };

    if (isMultiEdit) {
      const d: Partial<IClientFieldType> = compareObjects(state, initialState);
      const data = fieldTypeClientToRaw(d);
      const promiseList = [];
      activeEntityTypeList.forEach((dt) => {
        promiseList.push(patchEntityType(inboxId, dt.id, data));
      });
      await Promise.all(promiseList).then(() => {
        dispatch(adminSlice.actions.setActiveEntityTypeList([]));
        navigate(`/admin/inboxes/${inboxId}/fields`);
      });
    } else {
      const rawData = fieldTypeClientToRaw(mappedState);
      if (fieldId === 'new') {
        return postEntityType(inboxId, rawData).then(() => {
          navigate(`/admin/inboxes/${inboxId}/fields`);
        });
      } else {
        return patchEntityType(inboxId, fieldId, rawData).then(() => {
          navigate(`/admin/inboxes/${inboxId}/fields`);
        });
      }
    }
  };

  const handleDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          return dispatch(deleteEntityType([fieldId])).then(() => {
            navigate(`/admin/inboxes/${inboxId}/fields`);
          });
        }}
        text={t('admin:inboxes.sections.fieldTypeDelete')}
      />
    );
  };

  const initialState = useMemo(() => {
    if (activeEntityType) {
      return activeEntityType;
    } else {
      const obj: IClientFieldType = {
        name: '',
        type: 'text',
        isArchived: false,
        id: '',
        formats: [],
        options: [],
        isSensitive: false,
      };
      return obj;
    }
  }, [activeEntityType]);

  const isMultiEdit = useMemo(() => {
    return activeEntityTypeList?.length > 1 && !fieldId;
  }, [activeEntityTypeList, fieldId]);

  const { save, saving, error, hasChanges, setState, state } = useChangeTracker<IClientFieldType>(
    initialState,
    handleSave
  );

  const selectedTableTypes = useMemo(() => {
    return state?.tableDefinition?.entityTypes.map((e) => e.id) ?? [];
  }, [state]);

  const selectedComplexTypes = useMemo(() => {
    return state?.complexDefinition?.entityTypes.map((e) => e.id) ?? [];
  }, [state]);

  const mappedOptions = state?.options?.map((option) => {
    return { id: option.id, value: option.value };
  });

  const pageTitle = useMemo(() => {
    if (state?.name) {
      return state?.name;
    } else if (isMultiEdit) {
      return `Editing ${activeEntityTypeList?.length} Field types `;
    } else {
      return t('admin:fieldType.newType');
    }
  }, [isMultiEdit, activeEntityTypeList, state, t]);

  const handleNavBack = () => {
    if (hasChanges) {
      showDialog(
        <ConfirmationDialog
          confirmAction={() => {
            save(null);
            navigate(`/admin/inboxes/${inboxId}/fields`);
          }}
          cancelAction={() => navigate(`/admin/inboxes/${inboxId}/fields`)}
          confirmText={t('document:dialog.save')}
          cancelText={t('document:dialog.discard')}
          dialogType={'confirmation'}
          title={t('admin:unsavedChanges.title')}
          text={
            isMultiEdit
              ? t('admin:multiSave.description', { number: activeEntityTypeList.length })
              : t('admin:unsavedChanges.description')
          }
        />
      );
    } else {
      navigate(`/admin/inboxes/${inboxId}/fields`);
    }
  };

  const nextField = useMemo(() => {
    if (fieldId && inboxEntityTypes) {
      const sorted = [...inboxEntityTypes].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === fieldId);
      return sorted[index + 1];
    }
    return null;
  }, [fieldId, inboxEntityTypes]);

  const prevField = useMemo(() => {
    if (fieldId && inboxEntityTypes) {
      const sorted = [...inboxEntityTypes].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === fieldId);
      return sorted[index - 1];
    }
    return null;
  }, [fieldId, inboxEntityTypes]);

  const handleNavNextField = () => {
    if (nextField) {
      navigate(`/admin/inboxes/${inboxId}/fields/${nextField.id}`);
    }
  };

  const handleNavPrevField = () => {
    if (prevField) {
      navigate(`/admin/inboxes/${inboxId}/fields/${prevField.id}`);
    }
  };

  return (
    <form onSubmit={isMultiEdit ? handleSaveModal : save} className={s.form_body}>
      <FormHeaderNav
        onClick={handleNavBack}
        label={t('admin:page.backToOverview')}
        navOptions={
          fieldId === 'new'
            ? {}
            : {
                prev: {
                  label: t('admin:page.previous'),
                  onClick: handleNavPrevField,
                  active: !!prevField,
                },
                next: {
                  label: t('admin:page.next'),
                  onClick: handleNavNextField,
                  active: !!nextField,
                },
              }
        }
      />
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        errorMessage={error ? t('admin:fieldType.alreadyExists') : ''}
        title={pageTitle}
      />

      <div className={s.sections}>
        <FormSection hidden={isMultiEdit} title={t('admin:fieldType.config')}>
          <FormInputField
            testId={'fieldtype-name-input'}
            type={'text'}
            label={t('admin:fieldType.name')}
            description={t('admin:fieldType.nameDescription')}
            onChange={(val) => handleInput(val, 'name')}
            value={state.name}
            required
          />
          <FormInputField
            isCopyField
            hidden={fieldId === 'new'}
            disabled
            testId={'fieldtype-id-input'}
            type={'text'}
            label={t('admin:fieldType.id')}
            description={t('admin:fieldType.idDescription')}
            value={state?.id ?? ''}
          />
          <FormInputField
            testId={'fieldtype-provider-id-input'}
            type={'text'}
            label={t('admin:fieldType.providerId')}
            description={t('admin:fieldType.providerIdDescription')}
            onChange={(e) => handleInput(e, 'providerId')}
            value={state?.providerId ?? ''}
          />
          <FormInputField
            type={'toggle'}
            label={t('admin:fieldType.sensitive')}
            description={t('admin:fieldType.sensitiveDescription')}
            onChange={(val) => handleInput(val, 'isSensitive')}
            value={state.isSensitive}
          />
          <FormInputField
            testId={'fieldtype-dropdown'}
            type={'dropdown'}
            label={t('admin:fieldType.type')}
            description={t('admin:fieldType.typeDescription')}
            onChange={(val) => handleInput(val.value, 'type')}
            value={entityTypeTypes.find((ett) => ett.value === state.type)}
            dropdownOptions={entityTypeTypes}
            defaultDropdownOption={entityTypeTypes.find((ett) => ett.value === initialState?.type)}
          />
          {state.type === 'table' && (
            <div className={clsx(s.item, s.item__vertical)}>
              <div className={s.item_text}>
                <h4>{t('admin:fieldType.tableFields')}</h4>
                <p>{t('admin:fieldType.tableFieldsDescription')}</p>
              </div>
              <div className={s.item_action}>
                {filteredInboxEntityTypes?.length !== selectedTableTypes.length && (
                  <button
                    data-testid={'doctype-fieldtype-add'}
                    onClick={() =>
                      showDialog(
                        <AdminMultiSelectDialog
                          title={t('admin:inboxes.sections.fieldTypeSearch')}
                          description={t('admin:docType.fieldSearchDescription')}
                          handleCheckTypes={(newValue) =>
                            setState({
                              ...state,
                              tableDefinition: {
                                entityTypes: newValue.map((e) => ({ mandatory: false, id: e.id })),
                              },
                            })
                          }
                          selectedTypes={selectedTableTypes.map((e) => ({ id: e }))}
                          detailedList={(filteredInboxEntityTypes as MultiSelectDetailsType[]) ?? []}
                        />
                      )
                    }
                    type={'button'}
                    className={clsx(s.top_button, s.item_add)}
                  >
                    <PlusIcon /> {t('admin:inboxes.sections.addNew')}
                  </button>
                )}

                {state?.tableDefinition?.entityTypes && state?.tableDefinition?.entityTypes.length > 0 && (
                  <div className={s.row_list}>
                    <div className={s.field_type_header}>
                      <span>{t('admin:docType.fieldName')}</span>

                      <span style={{ width: 150, marginLeft: 0 }}>{t('admin:fieldType.tableMandatory')}</span>
                    </div>
                    {state?.tableDefinition?.entityTypes?.map((e) => (
                      <AdminTableItemRow
                        key={e.id}
                        handleEdit={(updatedItem) => {
                          setState({
                            ...state,
                            tableDefinition: {
                              entityTypes: state.tableDefinition.entityTypes.map((f) =>
                                f.id === updatedItem.id ? updatedItem : f
                              ),
                            },
                          });
                        }}
                        handleDelete={(id) => {
                          setState({
                            ...state,
                            tableDefinition: {
                              entityTypes: state.tableDefinition.entityTypes.filter((f) => f.id !== id),
                            },
                          });
                        }}
                        tableEntity={e}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          {state?.type === 'options' && (
            <FormInputField
              label={t('admin:fieldType.options')}
              description={t('admin:fieldType.optionsDescription')}
              type={'button'}
              buttonOptions={{
                type: 'normal',
                text: (
                  <>
                    <PlusIcon /> {t('admin:metadataType.values.addNew')}
                  </>
                ),
                onClick: () => {
                  showDialog(
                    <AdminInputDialog
                      title={t('admin:metadataType.values.addTitle')}
                      description={t('admin:metadataType.values.addDescription')}
                      handleAddItem={(e) =>
                        setState({ ...state, options: [...state.options, { id: convertToId(e), value: e }] })
                      }
                      currentList={state.options.map((e) => e.value)}
                    />
                  );
                },
              }}
            >
              <div className={clsx(s.row_list)}>
                <AdminSortableFieldOptions
                  options={mappedOptions}
                  handleDelete={(id) => {
                    setState({ ...state, options: state.options?.filter((option) => option.id !== id) });
                  }}
                  setItems={(e) => setState({ ...state, options: e })}
                />
                {/*<AdminSortableList*/}
                {/*  detailedItems={mappedOptions}*/}
                {/*  items={state.options.map((e) => e.value)}*/}
                {/*  setItems={(e) => setState({ ...state, options: e.map((eo) => ({ value: eo })) })}*/}
                {/*  handleRemoveType={(id) => {*/}
                {/*    setState({ ...state, options: state.options?.filter((option) => option.value !== id) });*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </FormInputField>
          )}
          {state.type === 'complex' && (
            <div className={clsx(s.item, s.item__vertical)}>
              <div className={s.item_text}>
                <h4>{t('admin:fieldType.complexFields')}</h4>
                <p>{t('admin:fieldType.complexFieldsDescription')}</p>
              </div>
              <div className={s.item_action}>
                {filteredInboxEntityTypes?.length !== selectedComplexTypes.length && (
                  <button
                    data-testid={'doctype-fieldtype-add'}
                    onClick={() =>
                      showDialog(
                        <AdminMultiSelectDialog
                          title={t('admin:inboxes.sections.fieldTypeSearch')}
                          description={t('admin:docType.fieldSearchDescription')}
                          handleCheckTypes={(newValue) =>
                            setState({
                              ...state,
                              complexDefinition: {
                                entityTypes: newValue.map((e) => ({ mandatory: false, id: e.id })),
                              },
                            })
                          }
                          selectedTypes={selectedComplexTypes?.map((e) => ({ id: e }))}
                          detailedList={(filteredInboxEntityTypes as MultiSelectDetailsType[]) ?? []}
                        />
                      )
                    }
                    type={'button'}
                    className={clsx(s.top_button, s.item_add)}
                  >
                    <PlusIcon /> {t('admin:inboxes.sections.addNew')}
                  </button>
                )}

                {state?.complexDefinition?.entityTypes &&
                  state?.complexDefinition?.entityTypes.length > 0 && (
                    <div className={s.row_list}>
                      <div className={s.field_type_header}>
                        <span>{t('admin:docType.fieldName')}</span>
                        <span style={{ marginRight: 35 }}>{t('admin:fieldType.tableMandatory')}</span>
                      </div>
                      {state?.complexDefinition?.entityTypes?.map((e) => (
                        <AdminTableItemRow
                          mandatory
                          key={e.id}
                          handleEdit={(updatedItem) => {
                            setState({
                              ...state,
                              complexDefinition: {
                                entityTypes: state.complexDefinition.entityTypes.map((f) =>
                                  f.id === updatedItem.id ? updatedItem : f
                                ),
                              },
                            });
                          }}
                          handleDelete={(id) => {
                            setState({
                              ...state,
                              complexDefinition: {
                                entityTypes: state.complexDefinition.entityTypes.filter((f) => f.id !== id),
                              },
                            });
                          }}
                          tableEntity={e}
                        />
                      ))}
                    </div>
                  )}
              </div>
            </div>
          )}
        </FormSection>

        <FormSection hidden={isMultiEdit} title={t('admin:fieldType.promptConfig.title')}>
          <FormInputField
            type={'text'}
            layout={'horizontal-large'}
            label={t('admin:fieldType.promptConfig.description')}
            description={t('admin:fieldType.promptConfig.descriptionDescription')}
            value={state.promptConfig?.description ?? ''}
            onChange={(e) => handleInput(e, 'promptConfig.description')}
          />
        </FormSection>

        <FormSection hidden={!fieldId} title={t('admin:fieldType.dangerZone')}>
          <FormInputField
            type={'button'}
            buttonOptions={{
              type: 'error',
              text: t('admin:fieldType.deleteType'),
              onClick: handleDelete,
            }}
            label={t('admin:fieldType.deleteType')}
            description={t('admin:fieldType.deleteTypeDescription')}
          />
        </FormSection>
      </div>
    </form>
  );
};

export default AdminInboxesFieldEdit;
