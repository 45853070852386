import Checkbox from '@components/shared/checkbox/Checkbox';
import StyledSelect, { DropdownOption } from '@components/shared/dropdown/StyledSelect';
import Tooltip from '@components/shared/tooltip/Tooltip';
import { concatRegexp, useKeyPress, uuid4hex } from '@shared/helpers/helpers';
import { DocumentEntity } from '@shared/models/document';
import { UrlParams } from '@shared/models/generic';
import { DocTypeSettings } from '@shared/models/inbox';
import { ToolType } from '@shared/models/labeler';
import { addDocumentEntity, labelerSlice } from '@shared/store/labelerSlice';
import { RootState, useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler-tools.module.scss';
import { ReactComponent as CheckMarkIcon } from '@svg/checkmark-icon.svg';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import { ReactComponent as SearchIcon } from '@svg/search-icon.svg';
import clsx from 'clsx';
import { cloneDeep, toNumber } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DocumentLabelerSidebarHover from '../sidebar/DocumentLabelerSidebarHover';

interface Props {}
const filterCategoryOccurrences = (
  dropdownOptions: DropdownOption[],
  documentEntities: DocumentEntity[],
  activeDocType: DocTypeSettings
) => {
  if (documentEntities) {
    return dropdownOptions.map((option) => {
      let type;
      let equalList;
      if (option.tag) {
        if (option.metadata?.parentId) {
          equalList = documentEntities.filter((e) => e.type === option.metadata?.parentId);
          type = activeDocType.entityTypes.find((li) => li.id === option.metadata?.parentId);
        } else {
          equalList = documentEntities.filter(
            (e) => e.categoryId === option.tag.value && e.type === option.value
          );
          type = activeDocType.entityTypes.find((li) => li.id === option.value);
        }

        if (type?.maxOccurrences) {
          if (equalList.length < type?.maxOccurrences) {
            return option;
          } else {
            return { ...option, isHidden: true } as DropdownOption;
          }
        } else {
          return option;
        }
      } else {
        equalList = documentEntities.filter((e) => e.type === option.value && e.categoryId == null);

        if (option.metadata?.parentId) {
          type = activeDocType.entityTypes.find((li) => li.id === option.metadata?.parentId);
        } else {
          type = activeDocType.entityTypes.find((li) => li.id === option.value);
        }

        if (type?.maxOccurrences) {
          if (equalList.length < type?.maxOccurrences) {
            return option;
          } else {
            return { ...option, isHidden: true } as DropdownOption;
          }
        } else {
          return option;
        }
      }
    });
  }
  return dropdownOptions;
};

// const filterEntityTypes = (entityTypes: IClientFieldType[], documentEntities) => {
//   if (documentEntities) {
//     return entityTypes.filter((et) => {
//       let shouldKeep;
//       let count = 0;
//       documentEntities.forEach((de) => {
//         if (de.type === et.id) {
//           count += 1;
//         }
//       });
//       if (et.maxOccurrences != null) {
//         shouldKeep = count < et.maxOccurrences;
//       } else {
//         shouldKeep = true;
//       }
//       return shouldKeep;
//     });
//   }
//   return entityTypes.sort((a, b) => {
//     return a.name.localeCompare(b.name);
//   });
// };

const DocumentLabelerTool: React.FC<Props> = () => {
  const { inboxId }: UrlParams = useParams();
  const dispatch = useDispatch();

  const tempEntity = useSelector((state) => state.labeler.tempEntity);
  const documentEntities = useSelector((state) => state.labeler.documentEntities);
  const isEditingMetadata = useSelector((state) => state.labeler.isEditingMetadata);
  const activeTool = useSelector((state) => state.labeler.activeTool) as ToolType;
  const allowedEntityTypes = useSelector((state: RootState) => state.document.allowedEntityTypes);
  const entityTypes = useSelector((state: RootState) => state.settings.entityTypes);
  const activePageNo = useSelector((state: RootState) => state.labeler.activePageNo);
  const docTypeCategories = useSelector((state: RootState) => state.document.docTypeCategories);
  const activeDocument = useSelector((state: RootState) => state.document.activeDocument);
  const docTypeSettings = useSelector((state: RootState) => state.settings.docTypeSettings);
  const activeMode = useSelector((state) => state.labeler.mode);

  const [isValueSelectActive, setIsValueSelectActive] = useState(true);
  const [dropdownValue, setDropdownValue] = useState<DropdownOption>(null);
  const [booleanState, setBooleanState] = useState(false);
  const [isValueInputFocus, setIsValueInputFocus] = useState(false);
  const [isValueValid, setIsValueValid] = useState<boolean>(undefined);
  const [groupedItems, setGroupedItems] = useState([]);

  const { t } = useTranslation();

  const activeFieldType = useMemo(() => {
    let et;
    if (!dropdownValue) return null;
    if (docTypeCategories) {
      docTypeCategories.forEach((dtc) => {
        if (dtc.id) {
          if (dropdownValue?.tag?.value === dtc.id) {
            et = entityTypes.find((aet) => aet.id === dropdownValue.value);
          }
        }
      });
      if (!et) {
        et = entityTypes.find((aet) => aet.id === dropdownValue.value);
      }
    } else {
      et = entityTypes.find((aet) => aet.id === dropdownValue.value);
    }
    return et;
  }, [docTypeCategories, dropdownValue, entityTypes]);

  const activeValueOptions = useMemo(() => {
    if (activeFieldType?.options) {
      const options = activeFieldType.options.map(
        (e) => ({ value: e.value, label: e.value }) as DropdownOption
      );
      dispatch(
        labelerSlice.actions.setTempEntity({
          uuid: uuid4hex(),
          rawValue: '',
          type: dropdownValue.value,
          valueLocations: [],
          pageNo: activePageNo,
          value: options[0]?.value,
        })
      );

      return options;
    } else {
      return [];
    }
  }, [activeFieldType, activePageNo, dispatch, dropdownValue]);

  const activeFieldTypeType = activeFieldType?.type ?? 'text';

  const handleToggle = (e) => {
    if (activeFieldTypeType === 'boolean') {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') return;
      e.preventDefault();
      setBooleanState(!booleanState);
    }
  };

  const isCompleted = useMemo(() => {
    if (activeMode === 'search') {
      return !!(tempEntity?.valueLocations && tempEntity?.value);
    } else {
      // Check if selected field type is not hidden (not over max occurrences)
      if (!dropdownValue) return false;

      // Check if selected field type is not hidden (not over max occurrences)
      if (groupedItems.filter((i) => !i?.isHidden).length === 0) {
        return false;
      }
    }

    // Check if value is valid (if checks were needed)
    if (isValueValid === false) return false;

    // Check if new entity has a value
    if (activeFieldTypeType === 'boolean') return true;
    switch (activeTool) {
      case 'default':
        return !!(tempEntity?.valueLocations && tempEntity?.value);
      case 'rectangle':
        return !!tempEntity?.valueLocations;

      default:
        return false;
    }
  }, [activeFieldTypeType, activeMode, activeTool, groupedItems, dropdownValue, isValueValid, tempEntity]);

  const activeDocTypeSettings = useMemo(() => {
    if (docTypeSettings) {
      return docTypeSettings.find((e) => e.docTypeId === activeDocument?.docTypeId)?.settings;
    }
  }, [activeDocument, docTypeSettings]);

  const handleConfirm = useCallback(() => {
    if (!isCompleted || isEditingMetadata) return;
    let entity = cloneDeep(tempEntity) as DocumentEntity;

    if (activeMode === 'label') {
      if (activeTool !== 'key-value') entity.type = dropdownValue.value;
      if (activeTool === 'rectangle' && activeFieldTypeType === 'boolean') {
        entity.value = booleanState;
        entity.dataURL = null;
        if (entity.valueLocations === null || entity.valueLocations.length === 0)
          entity.valueLocations = [{ pageNo: activePageNo, x1: -10, x2: -10, y1: -10, y2: -10 }] as any;
      }

      if (dropdownValue.tag && dropdownValue.metadata?.parentId) {
        const complexEntityType = entityTypes.find((et) => et.id === dropdownValue.metadata.parentId);

        // This is a complex field.
        entity = {
          uuid: uuid4hex(),
          valueLocations: [],
          pageNo: activePageNo,
          type: dropdownValue.metadata.parentId,
          value: { complex: {} },
          rawValue: null,
        };
        if (complexEntityType) {
          complexEntityType.complexDefinition.entityTypes.forEach((e, i) => {
            if (e.id === dropdownValue.value) {
              entity.value['complex'][i] = { ...tempEntity, type: e.id };
              delete entity.value['complex'][i].uuid;
            } else {
              entity.value['complex'][i] = {
                type: e.id,
                valueLocations: [],
                pageNo: activePageNo,
                value: null,
              };
            }
          });
        }
      }
      if (dropdownValue.tag) {
        const cat = docTypeCategories.find((dtc) => dtc.id === dropdownValue.tag.value);
        if (cat) entity.categoryId = cat.id;
      }
    }
    if (activeMode === 'search') {
      dispatch(
        labelerSlice.actions.setExternalSearchQueryItem({ value: entity.value.toString(), type: entity.type })
      );
    } else {
      dispatch(addDocumentEntity(inboxId, entity));
      if (dropdownValue.metadata && dropdownValue.metadata.parentId) {
        const parentComplex = entity as any;
        const nextEmpty = Object.entries(parentComplex.value.complex).find(([_, v]) => !v['value']);
        if (nextEmpty) {
          dispatch(labelerSlice.actions.setActiveComplexEditId(parentComplex.uuid + nextEmpty[0]));
          dispatch(labelerSlice.actions.setTempEntity(null));
          dispatch(labelerSlice.actions.setActiveMode('complex'));
        }
      }
    }
    dispatch(labelerSlice.actions.setTempEntity(null));
    setIsValueSelectActive(true);
  }, [
    activeFieldTypeType,
    activePageNo,
    activeMode,
    isCompleted,
    isEditingMetadata,
    tempEntity,
    activeTool,
    dropdownValue,
    dispatch,
    inboxId,
    booleanState,
    docTypeCategories,
  ]);

  useEffect(() => {
    if (activeMode === 'search') dispatch(labelerSlice.actions.setActiveTool('default'));
    else {
      switch (activeFieldTypeType) {
        case 'boolean':
          dispatch(labelerSlice.actions.setActiveTool('rectangle'));
          break;
        case 'text':
          dispatch(labelerSlice.actions.setActiveTool('default'));
          break;
        case 'image':
          dispatch(labelerSlice.actions.setActiveTool('rectangle'));
          break;
        case 'table':
          dispatch(labelerSlice.actions.setActiveTool('table'));
          break;
        case 'complex':
          dispatch(labelerSlice.actions.setActiveTool('default'));
          break;
      }
    }
  }, [activeMode, dispatch, activeFieldTypeType]);

  useEffect(() => {
    dispatch(labelerSlice.actions.setTempEntity(null));
  }, [dispatch]);

  useEffect(() => {
    if (activeTool) {
      dispatch(labelerSlice.actions.setTempEntity(null));
    }
  }, [activeTool, dispatch]);

  useEffect(() => {
    if (tempEntity && tempEntity.valueLocations) {
      setIsValueSelectActive(false);
    } else {
      setIsValueInputFocus(false);
      setIsValueSelectActive(true);
    }
  }, [tempEntity]);

  useEffect(() => {
    if (dropdownValue) {
      dispatch(labelerSlice.actions.setSelectedEntityTypeId(dropdownValue.value));
      if (dropdownValue.tag) {
        dispatch(labelerSlice.actions.setSelectedCategoryId(dropdownValue.tag.value));
      }
    }
    return () => {
      dispatch(labelerSlice.actions.setSelectedEntityTypeId(null));
      dispatch(labelerSlice.actions.setSelectedCategoryId(null));
    };
  }, [dispatch, dropdownValue]);

  useEffect(() => {
    if (activeFieldTypeType === 'boolean' && !tempEntity) {
      dispatch(
        labelerSlice.actions.setTempEntity({
          uuid: uuid4hex(),
          rawValue: '',
          type: dropdownValue.value,
          valueLocations: [],
          pageNo: activePageNo,
          value: false,
        })
      );
    }
  }, [activeFieldTypeType, dispatch, tempEntity, dropdownValue, activePageNo]);

  useEffect(() => {
    const grouped = [];

    if (allowedEntityTypes && allowedEntityTypes.length > 0) {
      // First add existing categories
      if (docTypeCategories) {
        const entityList: DropdownOption[] = [];
        docTypeCategories.forEach((dtc) => {
          dtc.entityTypes.forEach((entityType) => {
            if (!entityType.isArchived) {
              if (entityType.type === 'complex') {
                entityType.complexDefinition.entityTypes.forEach((e) => {
                  const type = entityTypes.find((et) => et.id === e.id);
                  // We are using the tag to keep track of the parentId
                  const item: DropdownOption = {
                    label: `${entityType.name} / ${type?.name}`,
                    value: e.id,
                    tag: { name: dtc.name, value: dtc.id, isMinimal: true },
                    metadata: { parentId: entityType.id },
                  };
                  if (dtc.color) item.color = dtc.color;
                  entityList.push(item);
                });
                // grouped.push(it);
              } else {
                const obj: any = {
                  tag: { name: dtc.name, value: dtc.id, isMinimal: true },
                  label: entityType.name,
                  value: entityType.id,
                };
                if (dtc.color) obj.color = dtc.color;
                entityList.push(obj);
              }
            }
          });
        });
        allowedEntityTypes.forEach((ae) => {
          if (
            entityList.findIndex((e) => {
              if (e.metadata?.parentId) {
                return e.metadata?.parentId === ae.id;
              } else {
                return e.value === ae.id;
              }
            }) === -1
          ) {
            if (ae.type === 'complex') {
              ae.complexDefinition.entityTypes.forEach((e) => {
                const type = entityTypes.find((et) => et.id === e.id);
                // We are using the tag to keep track of the parentId
                const item: DropdownOption = {
                  label: `${ae.name} / ${type?.name}`,
                  value: type.id,
                  tag: { name: ae.name + ' - complex', value: ae.id, isMinimal: true },
                  color: 'white',
                  metadata: { parentId: ae.id },
                };
                entityList.push(item);
              });
            } else {
              entityList.push({
                label: ae.name,
                value: ae.id,
              });
            }
          }
        });

        const filterOccurrences = filterCategoryOccurrences(
          entityList,
          documentEntities,
          activeDocTypeSettings
        );

        filterOccurrences.forEach((opt) => {
          if (opt.tag) {
            const exi = grouped.findIndex((e) => e.label === opt.tag.name);
            if (exi !== -1) {
              grouped[exi].options.push(opt);
            } else {
              grouped.push({
                label: opt.tag.name,
                options: [opt],
              });
            }
          } else {
            grouped.push(opt);
          }
        });
        grouped.sort((a, b) => {
          return toNumber(a.options != null) - toNumber(b.options != null);
        });
        filterOccurrences.sort((a: any, b: any) => {
          return toNumber(a.tag != null) - toNumber(b.tag != null);
        });
        console.log(grouped);
        setGroupedItems(grouped);
      } else {
        const filterOccurrences = filterCategoryOccurrences(
          [...allowedEntityTypes].map((e) => {
            return { label: e.name, value: e.id, isHidden: false } as DropdownOption;
          }),
          documentEntities,
          activeDocTypeSettings
        );

        filterOccurrences.sort((a: any, b: any) => {
          return (
            toNumber(a.options != null) - toNumber(b.options != null) ||
            toNumber(a.tag != null) - toNumber(b.tag != null)
          );
        });
        setGroupedItems(filterOccurrences);
      }
    } else {
      setGroupedItems([]);
    }
  }, [allowedEntityTypes, docTypeCategories, documentEntities, activeDocTypeSettings]);

  useKeyPress('Enter', handleConfirm);
  useKeyPress(' ', handleToggle);

  useEffect(() => {
    if (tempEntity?.value && typeof tempEntity?.value === 'string' && allowedEntityTypes?.length > 0) {
      const selectedType = allowedEntityTypes.find((ae) => ae.id === dropdownValue?.value);
      if (selectedType?.formats) {
        setIsValueValid(concatRegexp(selectedType.formats).test(tempEntity.value));
      } else {
        setIsValueValid(undefined);
      }
    } else {
      setIsValueValid(undefined);
    }
  }, [allowedEntityTypes, dropdownValue, tempEntity]);

  return (
    <div className={s.tool} data-testid={'document-toolbar'}>
      {activeMode === 'label' && (
        <>
          <span className={s.text}>{t('document:toolbar.type')}</span>
          <div style={{ minWidth: 250 }}>
            <StyledSelect
              isLoading={groupedItems[0] === null}
              onChange={(e) => setDropdownValue(e as any)}
              options={groupedItems.length > 0 ? groupedItems : []}
              value={dropdownValue}
              defaultValue={groupedItems[0]}
            />
          </div>
        </>
      )}
      {activeFieldTypeType === 'options' && activeMode !== 'search' && (
        <>
          <span className={s.text}>{t('document:toolbar.value')}</span>
          <div className={s.input} style={{ width: 200 }}>
            <StyledSelect
              options={activeValueOptions}
              value={
                tempEntity && activeValueOptions
                  ? activeValueOptions.find((e) => e.value === tempEntity.value)
                  : activeValueOptions[0]
              }
              defaultValue={activeValueOptions[0]}
              onChange={(v) => {
                const val = (v as any).value;
                dispatch(
                  labelerSlice.actions.setTempEntity({
                    uuid: uuid4hex(),
                    rawValue: '',
                    type: dropdownValue.value,
                    valueLocations: [],
                    pageNo: activePageNo,
                    value: val,
                  })
                );
              }}
            />
          </div>
          <button
            data-testid={'toolbar-confirm'}
            onClick={handleConfirm}
            disabled={!isCompleted}
            className={clsx(s.confirm, {
              [s.confirm__active]: isCompleted,
            })}
          >
            {/*{activeMode == 'search' && <SearchIcon />}*/}
            {activeMode == 'label' && <CheckMarkIcon />}
          </button>
        </>
      )}

      {((activeFieldTypeType !== 'table' && activeFieldTypeType !== 'options') || activeMode === 'search') &&
        groupedItems.filter((i) => !i?.isHidden).length !== 0 && (
          <>
            <span className={s.text}>
              {activeTool === 'rectangle' ? t('document:toolbar.visual') : t('document:toolbar.value')}
            </span>

            <div
              style={{
                width: Math.min(
                  Math.max(tempEntity ? tempEntity?.value.toString().length * 8 : 160, 160),
                  250
                ),
              }}
              className={clsx(
                s.selector,
                { [s.selector__focus]: isValueInputFocus },

                {
                  [s.selector__active]: isValueSelectActive || activeTool === 'default',
                },
                {
                  [s.selector__selected]: tempEntity && tempEntity.valueLocations,
                }
              )}
            >
              <PlusIcon className={s.pending_icon} />
              {
                <>
                  {activeTool === 'rectangle' && (
                    <DocumentLabelerSidebarHover imageUrl={tempEntity?.dataURL}>
                      <div className={s.image} data-hj-suppress>
                        {tempEntity?.dataURL && (
                          <div className={s.image_wrapper}>
                            <img src={tempEntity?.dataURL} alt="data" />
                          </div>
                        )}
                      </div>
                    </DocumentLabelerSidebarHover>
                  )}
                  {activeTool === 'table' && <div>{t('document:table')}</div>}

                  {activeTool !== 'rectangle' && activeTool !== 'table' && (
                    <>
                      {isValueValid !== undefined && (
                        <Tooltip
                          position={'bottom'}
                          content={
                            isValueValid
                              ? `${t('document:valid')} ${dropdownValue.label}`
                              : `${t('document:invalid')} ${dropdownValue.label}`
                          }
                        >
                          <div
                            className={clsx(s.validation_icon, {
                              [s.validation_icon__valid]: isValueValid,
                            })}
                          >
                            {isValueValid ? <CheckMarkIcon /> : <CrossIcon />}
                          </div>
                        </Tooltip>
                      )}
                      <input
                        data-testid={'tool-value-input'}
                        data-hj-suppress
                        style={isValueValid !== undefined ? { paddingLeft: 26 } : {}}
                        onFocus={() => setIsValueInputFocus(true)}
                        onBlur={() => setIsValueInputFocus(false)}
                        type="text"
                        placeholder={t('document:toolbar.typeOrSelect')}
                        className={clsx(
                          s.input,
                          { [s.input__invalid]: isValueValid === false },
                          { [s.input__valid]: isValueValid === true }
                        )}
                        value={(tempEntity?.value as string) ?? ''}
                        onChange={(e) => {
                          // OLD ManualInput
                          if (!tempEntity) {
                            dispatch(
                              labelerSlice.actions.setTempEntity({
                                uuid: uuid4hex(),
                                rawValue: '',
                                type: dropdownValue.value,
                                valueLocations: [],
                                pageNo: activePageNo,
                                value: e.target.value,
                              })
                            );
                          } else {
                            dispatch(
                              labelerSlice.actions.setTempEntity({
                                ...tempEntity,
                                value: e.target.value,
                              })
                            );
                          }
                        }}
                      />
                    </>
                  )}
                </>
              }
            </div>

            {activeFieldTypeType === 'boolean' && activeTool === 'rectangle' && (
              <>
                <span className={s.text}>{t('document:checked')}</span>
                <Checkbox checked={booleanState} onClick={() => setBooleanState(!booleanState)} />
              </>
            )}

            <button
              data-testid={'toolbar-confirm'}
              onClick={handleConfirm}
              disabled={!isCompleted}
              className={clsx(s.confirm, {
                [s.confirm__active]: isCompleted,
              })}
            >
              {activeMode == 'search' && <SearchIcon />}
              {activeMode == 'label' && <CheckMarkIcon />}
            </button>
          </>
        )}
    </div>
  );
};

export default DocumentLabelerTool;
