import AdminItemRow from '@components/admin/components/AdminItemRow.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import { IClientTag } from '@shared/helpers/converters/tag.ts';
import { extendedSearch, globalFuseOptions } from '@shared/helpers/helpers.ts';
import { useModal } from '@shared/hooks/useModal.tsx';
import { useNotification } from '@shared/hooks/useNotificationBar.tsx';
import { deleteTagTypes } from '@shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import p from '@shared/styles/component/admin/admin-pages/admin-page.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['name'],
};
const AdminInboxesTags: React.FC = () => {
  const inboxTags = useSelector((state) => state.admin.inboxTagTypes);

  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [fuseData, setFuseData] = useState([]);
  const [searchResults, setSearchResults] = useState<IClientTag[]>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const fuse = new Fuse(fuseData, fuseOptions);

  const handleMatches = (input) => {
    setSearchResults(extendedSearch(input, fuse));
  };

  const handleInput = (value) => {
    if (value === '') {
      setSearchResults(inboxTags);
    } else {
      handleMatches(value);
    }
  };
  const handleDelete = (setting: IClientTag) => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => dispatch(deleteTagTypes([setting.id]))}
        text={t('admin:inboxes.sections.tagTypeDelete')}
      />
    );
  };
  const handleMultiDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          dispatch(deleteTagTypes(selectedTags));
          setSelectedTags([]);
        }}
        text={t('admin:inboxes.sections.tagTypeMultiDelete')}
      />
    );
  };

  useEffect(() => {
    if (inboxTags) {
      setSearchResults(inboxTags);
      setFuseData(inboxTags);
    }
  }, [inboxTags]);

  return (
    <div className={clsx(s.form_body, s.form_body_scroll)}>
      <div className={p.body_header}>
        <h2>{t('admin:inboxes.tagTypes')}</h2>
      </div>
      <p className={p.body_description}>{t('admin:inboxes.tagTypesDescription')}</p>
      <FormSection
        noStyle
        scroll
        title={t('admin:inboxes.tagTypes')}
        add={{
          onClick: () => navigate('new'),
          label: t('admin:inboxes.sections.addNew'),
        }}
        search={{
          onChange: (e) => handleInput(e),
          placeholder: t('admin:inboxes.sections.tagTypeSearch'),
        }}
        select={{
          handleDelete: handleMultiDelete,
          allValues: inboxTags?.map((e) => e.id) ?? [],
          selectedValues: selectedTags,
          setSelectedValues: setSelectedTags,
        }}
        copy={{
          copyValues: inboxTags?.map((dt) => dt.id) ?? [],
        }}
      >
        <div className={clsx(s.row_list)}>
          {searchResults &&
            searchResults.map((setting, index) => {
              const isChecked = selectedTags.findIndex((e) => e === setting.id) !== -1;

              return (
                <AdminItemRow
                  isChecked={isChecked}
                  setIsChecked={(val) => {
                    if (val) {
                      setSelectedTags([...selectedTags, setting.id]);
                    } else {
                      setSelectedTags(selectedTags.filter((e) => e !== setting.id));
                    }
                  }}
                  animationSettings={{
                    enabled: true,
                    delay: 50 + index * 25,
                  }}
                  handleDelete={() => handleDelete(setting)}
                  handleNav={() => {
                    navigate(setting.id);
                  }}
                  handleCopy={() => {
                    navigator.clipboard.writeText(setting.id);
                    showNotification(t('home:notifications.copied'), 'success');
                  }}
                  key={'tagType' + setting.id}
                  tagType={setting}
                  title={setting.name}
                />
              );
            })}
          {searchResults?.length === 0 && (
            <div className={s.no_results}>{t('admin:inboxes.sections.noTagTypeFound')}</div>
          )}
        </div>
      </FormSection>
    </div>
  );
};
export default AdminInboxesTags;
