import AdminDocTypeMetadataRow from '@components/admin/components/AdminDocTypeMetadataRow.tsx';
import AdminItemRow from '@components/admin/components/AdminItemRow.tsx';
import AdminMultiSelectDialog, {
  MultiSelectDetailsType,
} from '@components/admin/components/AdminMultiSelectDialog.tsx';
import AdminSortableCategories from '@components/admin/components/AdminSortableCategories.tsx';
import AdminSortableFields from '@components/admin/components/AdminSortableFields.tsx';
import FormBodyHeader from '@components/admin/components/form/FormBodyHeader.tsx';
import { FormHeaderNav } from '@components/admin/components/form/FormHeaderNav.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import {
  docTypeClientToRaw,
  IClientDocType,
  IClientDocTypeSubType,
} from '@shared/helpers/converters/doctype.ts';
import {
  compareObjects,
  extendedSearch,
  globalFuseOptions,
  handleIndeterminate,
} from '@shared/helpers/helpers.ts';
import useChangeTracker from '@shared/hooks/useChangeTracker.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import { useQuery } from '@shared/hooks/useQuery.tsx';
import { AdminFlatDocTypeCategory } from '@shared/models/admin.ts';
import { DocTypeFieldType } from '@shared/models/inbox.ts';
import { adminSlice, deleteDocTypes, patchDocType, postDocType } from '@shared/store/adminSlice.ts';
import { validTopologyTypesSelector } from '@shared/store/inboxSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import { ReactComponent as ReloadIcon } from '@svg/refresh-icon.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareMemo } from 'use-deep-compare';
import { combineObjects } from '@components/admin/helpers/helpers.ts';

export type SaveState = 'no-changes' | 'changes' | 'saving';

interface Props {}

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['name'],
};

const AdminInboxesDoctypeEdit: React.FC<Props> = () => {
  const { inboxId, doctypeId } = useParams();
  const { ids, topologyType } = useQuery();

  const inboxMetadataTypes = useSelector((state) => state.admin.inboxMetadataTypes);
  const inboxDocTypes = useSelector((state) => state.admin.inboxDocTypes) ?? [];
  const inboxEntityTypes = useSelector((state) => state.admin.inboxEntityTypes);
  const newTempDocType = useSelector((state) => state.admin.newTempDocType);
  const inboxApprovalChecks = useSelector((state) => state.admin.inboxApprovalChecks);
  const validTopologyTypes = useSelector(validTopologyTypesSelector);
  const inboxMasterdataTables = useSelector((state) => state.admin.inboxMasterdataTables);

  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const { t } = useTranslation();
  const { state: navState } = useLocation();
  const navigate = useNavigate();
  const [fuseData, setFuseData] = useState([]);
  const [subtypeResults, setSubtypeResults] = useState<IClientDocTypeSubType[]>([]);

  useEffect(() => {
    if (!navState?.NO_RESET) {
      dispatch(adminSlice.actions.setNewTempDocType(null));
      dispatch(adminSlice.actions.setNewTempSubTypes(null));
    }
  }, [dispatch, navState]);

  const fuse = new Fuse(fuseData, fuseOptions);

  const activeDoctype = useMemo(() => {
    if (inboxDocTypes && inboxDocTypes.length > 0)
      return inboxDocTypes.find((docType) => docType.id === doctypeId);
  }, [inboxDocTypes, doctypeId]);

  const multiDocTypeIds = useMemo(() => {
    if (doctypeId !== 'multi') return [];
    return ids.split(',') ?? [];
  }, [doctypeId, ids]);

  const defaultTopologyType = topologyType ?? 'bundle';

  const handleRemoveEntityType = (entityTypeId: string) => {
    if (state.categories) {
      const mapped = state.categories.map((prevCat) => {
        const editableCat = { ...prevCat };
        const filtered = editableCat.entityTypes.filter((et) => et !== entityTypeId);
        return { ...editableCat, entityTypes: filtered };
      });
      handleSetCategories(mapped);
    }
    setState((fs) => {
      const copy = cloneDeep(fs);
      let entitiesCopy = cloneDeep(copy.entityTypes);
      entitiesCopy = entitiesCopy.filter((e) => e.id !== entityTypeId);
      copy.entityTypes = entitiesCopy;
      return copy;
    });
  };

  const handleSetEntityTypes = (entityTypes: DocTypeFieldType[]) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy.entityTypes = entityTypes;
      return copy;
    });
  };
  const handleRemoveMetadata = (metadataKey: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      let metadataCopy = cloneDeep(copy.metadataKeys);
      metadataCopy = metadataCopy.filter((e) => e.id !== metadataKey);
      copy.metadataKeys = metadataCopy;
      return copy;
    });
  };

  const handleSetMetadataValue = (id: string, value: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy.metadataKeys = fs.metadataKeys.map((e) => {
        if (e.id === id) {
          return { id, value };
        } else {
          return e;
        }
      });
      return copy;
    });
  };
  const handleCheckMetadata = (updatedList: string[]) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy.metadataKeys = updatedList.map((e) => {
        const exists = fs.metadataKeys.find((m) => m.id === e);
        return exists ?? { id: e, value: '' };
      });
      return copy;
    });
  };

  const handleDeleteCategory = (categoryId: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      let catCopy = cloneDeep(copy.categories);
      catCopy = catCopy.filter((e) => e.id !== categoryId);
      copy.categories = catCopy;
      return copy;
    });
  };
  const handleUpdateCategory = (categoryId: string, category: AdminFlatDocTypeCategory) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      const catCopy = cloneDeep(copy.categories);
      const catIndex = catCopy.findIndex((cat) => cat.id === categoryId);
      catCopy[catIndex] = category;
      copy.categories = catCopy;
      return copy;
    });
  };
  const handleAddCategory = () => {
    if (state.categories.findIndex((e) => e.id === '_temp_') === -1) {
      setState((fs) => {
        const copy = cloneDeep(fs);
        copy.categories = [...copy.categories, { id: '_temp_', name: '', entityTypes: [] }];
        return copy;
      });
    }
  };
  const handleSetCategories = (items: AdminFlatDocTypeCategory[]) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy.categories = items;
      return copy;
    });
  };

  const handleCheckTypes = (updatedList: string[]) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      copy.entityTypes = updatedList.map((up) => {
        const ex = copy.entityTypes.find((e) => e.id === up);
        if (ex) return ex;
        return { id: up, maxOccurrences: 20, minOccurrences: 0, approvalThreshold: 1 } as DocTypeFieldType;
      });
      return copy;
    });
  };

  const handleInput = (value: any, field: string) => {
    setState((fs) => {
      const copy = cloneDeep(fs);
      const fields = field.split('.');
      fields.reduce((acc, cur, i) => (acc[cur] = fields.length - 1 === i ? value : acc[cur] || {}), copy);
      return copy;
    });
  };

  const handleDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          deleteDocTypes(inboxId, [doctypeId]);
          navigate(`/admin/inboxes/${inboxId}/doctypes`);
        }}
        text={t('admin:inboxes.sections.docTypeDelete')}
      />
    );
  };

  const handleSaveModal = (e) => {
    const d = compareObjects(state, initialState);

    e.preventDefault();
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          save(null);
          navigate(`/admin/inboxes/${inboxId}/doctypes`);
        }}
        cancelAction={() => null}
        confirmText={t('document:dialog.save')}
        cancelText={t('document:dialog.cancel')}
        dialogType={'confirmation'}
        title={t('admin:multiSave.title')}
        text={
          <div>
            <p>{t('admin:multiSave.changeLogDescription', { number: multiDocTypeIds.length })}</p>
            <div style={{ textAlign: 'start', marginTop: 10 }}>
              {Object.keys(d).map((k) => (
                <li key={k}>{t(`admin:docType.${k}`)}</li>
              ))}
            </div>
          </div>
        }
      />
    );
  };

  const isMultiEdit = useMemo(() => doctypeId === 'multi', [doctypeId]);

  const initialState = useDeepCompareMemo(() => {
    const allChecks = {};
    if (inboxApprovalChecks)
      inboxApprovalChecks.forEach((check) => {
        allChecks[check] = false;
      });
    allChecks['auto_approve'] = true;
    allChecks['classification_confidence'] = true;
    if (activeDoctype) {
      const clone = cloneDeep(activeDoctype);
      const inboxChecks = { ...allChecks };
      Object.entries(clone.approvalChecks).forEach(([k, v]) => {
        inboxChecks[k] = v;
      });
      clone.approvalChecks = inboxChecks;
      return activeDoctype;
    }
    if (isMultiEdit) {
      const items = [...inboxDocTypes]?.filter((item) => multiDocTypeIds.includes(item.id));
      const test = combineObjects(items);

      items
        .map((e) => e.approvalChecks)
        .forEach((checks) => {
          Object.entries(checks).forEach(([k]) => {
            allChecks[k] = undefined;
          });
        });
      Object.values(combineObjects(items.map((e) => Object.keys(e.approvalChecks)))).forEach((key) => {
        const stringKey = key as string;
        allChecks[stringKey] = true;
      });
      const el: IClientDocType = {
        name: '',
        entityTypes: test.entityTypes ?? undefined,
        approvalThreshold: test.approvalThreshold ?? undefined,
        autoApprove: test.autoApprove ?? undefined,
        ocrThreshold: test.ocrThreshold ?? undefined,
        ageThreshold: test.ageThreshold ?? undefined,
        metadataKeys: test.metadataKeys ?? undefined,
        categories: test.categories ?? undefined,
        topologyType: test.topologyType ?? defaultTopologyType,
        approvalChecks: allChecks,
      };
      return el;
    }
    const el: IClientDocType = {
      name: '',
      entityTypes: [],
      approvalThreshold: 0,
      ocrThreshold: 0,
      ageThreshold: 7,
      autoApprove: false,
      metadataKeys: [],
      categories: [],
      approvalChecks: allChecks,
      topologyType: defaultTopologyType,
    };
    return el;
  }, [activeDoctype, isMultiEdit, inboxApprovalChecks, inboxDocTypes, multiDocTypeIds, doctypeId]);

  const handleSave = async () => {
    const mappedState = { ...state };

    try {
      if (isMultiEdit) {
        const promiseList = [];
        const d = compareObjects(state, initialState);
        multiDocTypeIds.forEach((docTypeId) => {
          const docType = inboxDocTypes.find((e) => e.id === docTypeId);
          const docTypeApprovalChecks = docType.approvalChecks;

          const approvalChecks = { ...docTypeApprovalChecks };
          if (d['entityTypes'] && d['entityTypes'].length === 0) {
            approvalChecks['entity_occurrences'] = false;
            approvalChecks['ocr_confidence'] = false;
          } else if (docType.entityTypes && docType.entityTypes.length > 0) {
            approvalChecks['entity_occurrences'] = true;
            approvalChecks['ocr_confidence'] = true;
          }
          Object.entries(state.approvalChecks).forEach(([k, v]) => {
            if (v === true) {
              approvalChecks[k] = true;
            } else if (v === false) {
              if (approvalChecks[k] != null) delete approvalChecks[k];
            }
          });

          d['approvalChecks'] = approvalChecks;
          const rawData = docTypeClientToRaw(d as IClientDocType);

          promiseList.push(patchDocType(inboxId, docTypeId, rawData));
        });
        return await Promise.all(promiseList).then(() => {
          navigate(`/admin/inboxes/${inboxId}/doctypes`);
        });
      } else {
        const rawData = docTypeClientToRaw(mappedState);
        if (doctypeId === 'new') {
          return postDocType(inboxId, rawData).then(() => {
            navigate(`/admin/inboxes/${inboxId}/doctypes`);
          });
        } else {
          return patchDocType(inboxId, doctypeId, rawData).then(() => {});
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubtypeInput = (value) => {
    if (value === '') {
      setSubtypeResults(state.subtypes ?? []);
    } else {
      setSubtypeResults(extendedSearch(value, fuse));
    }
  };

  const handleDeleteSubtype = (subType: IClientDocTypeSubType) => {
    const newTempSubTypes = [...state.subtypes].filter((st) => st.id !== subType.id);
    setState({ ...state, subtypes: newTempSubTypes });
  };

  const { save, saving, state, setState, hasChanges, error } = useChangeTracker<IClientDocType>(
    initialState,
    handleSave
  );

  useEffect(() => {
    if (state.subtypes) {
      setSubtypeResults(state.subtypes ?? []);
      setFuseData(state.subtypes ?? []);
    }
  }, [state]);

  const pageTitle = useMemo(() => {
    if (activeDoctype?.name) {
      return activeDoctype?.name;
    } else if (isMultiEdit) {
      return `Editing ${multiDocTypeIds?.length} Document types `;
    } else {
      return t('admin:docType.newType');
    }
  }, [isMultiEdit, multiDocTypeIds, activeDoctype?.name, t]);

  const filteredEntities = useMemo(() => {
    if (state?.entityTypes && inboxEntityTypes) {
      return inboxEntityTypes.filter((et) => state.entityTypes.find((e) => e.id === et.id));
    }
    return [];
  }, [state, inboxEntityTypes]);

  const handleNavBack = () => {
    //Reset temp types

    if (hasChanges) {
      const d = compareObjects(state, initialState);

      showDialog(
        <ConfirmationDialog
          confirmAction={() => {
            save(null);
            navigate(`/admin/inboxes/${inboxId}/doctypes`);
          }}
          cancelAction={() => navigate(`/admin/inboxes/${inboxId}/doctypes`)}
          confirmText={t('document:dialog.save')}
          cancelText={t('document:dialog.discard')}
          dialogType={'confirmation'}
          title={t('admin:unsavedChanges.title')}
          text={
            isMultiEdit ? (
              <div>
                <p>{t('admin:multiSave.unsavedChangeLogDescription', { number: multiDocTypeIds.length })}</p>
                <div style={{ textAlign: 'start', marginTop: 10 }}>
                  {Object.keys(d).map((k) => (
                    <li>{t(`admin:docType.${k}`)}</li>
                  ))}
                </div>
              </div>
            ) : (
              t('admin:unsavedChanges.description')
            )
          }
        />
      );
    } else {
      navigate(`/admin/inboxes/${inboxId}/doctypes`);
    }
  };

  const hasMultiEditMetadataState = useMemo(() => {
    if (doctypeId !== 'multi') return false;
    if (initialState.metadataKeys?.length == 0) return false;
    return state.metadataKeys == undefined || isEqual(state.metadataKeys, initialState.metadataKeys);
  }, [doctypeId, initialState.metadataKeys, state.metadataKeys]);

  const hasMultiEditFieldState = useMemo(() => {
    if (doctypeId !== 'multi') return false;
    if (initialState.entityTypes?.length == 0) return false;
    return state.entityTypes == undefined || isEqual(state.entityTypes, initialState.entityTypes);
  }, [doctypeId, initialState.entityTypes, state.entityTypes]);

  useEffect(() => {
    if (newTempDocType && newTempDocType !== state && navState?.NO_RESET) {
      setState(newTempDocType);
      dispatch(adminSlice.actions.setNewTempDocType(null));
    }
  }, [navState, newTempDocType, state, setState, dispatch]);

  const nextDocType = useMemo(() => {
    if (doctypeId && inboxDocTypes) {
      const sorted = [...inboxDocTypes]
        .filter((e) => e.topologyType === state.topologyType)
        .sort((a, b) => {
          return a.topologyType.localeCompare(b.topologyType) || a.name.localeCompare(b.name);
        });
      const index = sorted.findIndex((e) => e.id === doctypeId);
      return sorted[index + 1];
    }
    return null;
  }, [doctypeId, inboxDocTypes, state.topologyType]);

  const prevDocType = useMemo(() => {
    if (doctypeId && inboxDocTypes) {
      const sorted = [...inboxDocTypes]
        .filter((e) => e.topologyType === state.topologyType)
        .sort((a, b) => {
          return a.topologyType.localeCompare(b.topologyType) || a.name.localeCompare(b.name);
        });
      const index = sorted.findIndex((e) => e.id === doctypeId);
      return sorted[index - 1];
    }
    return null;
  }, [state?.topologyType, doctypeId, inboxDocTypes]);

  const handleNavNextDoctype = () => {
    if (nextDocType) {
      navigate(`/admin/inboxes/${inboxId}/doctypes/${nextDocType.id}`);
    }
  };

  const handleNavPrevDoctype = () => {
    if (prevDocType) {
      navigate(`/admin/inboxes/${inboxId}/doctypes/${prevDocType.id}`);
    }
  };

  return (
    <form onSubmit={isMultiEdit ? handleSaveModal : save} className={s.form_body}>
      <FormHeaderNav
        onClick={handleNavBack}
        label={t('admin:page.backToOverview')}
        navOptions={
          doctypeId === 'new'
            ? {}
            : {
                prev: {
                  label: t('admin:page.previous'),
                  onClick: handleNavPrevDoctype,
                  active: !!prevDocType,
                },
                next: { label: t('admin:page.next'), onClick: handleNavNextDoctype, active: !!nextDocType },
              }
        }
      />
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        errorMessage={error ? t('admin:docType.alreadyExists') : ''}
        title={pageTitle}
      />

      <div className={s.sections}>
        <FormSection hidden={isMultiEdit} title={t('admin:docType.config')}>
          <FormInputField
            testId={'doctype-name-input'}
            type={'text'}
            description={t('admin:docType.nameDescription')}
            label={t('admin:docType.name')}
            required
            onChange={(e) => handleInput(e, 'name')}
            value={state?.name ?? ''}
          />
          <FormInputField
            hidden={doctypeId === 'new'}
            testId={'doctype-id-input'}
            type={'text'}
            disabled
            isCopyField
            description={t('admin:docType.idDescription')}
            label={t('admin:docType.id')}
            value={state?.id ?? ''}
          />
          <FormInputField
            testId={'doctype-provider-id-input'}
            type={'text'}
            description={t('admin:docType.providerIdDescription')}
            label={t('admin:docType.providerId')}
            onChange={(e) => handleInput(e, 'providerId')}
            value={state?.providerId ?? ''}
          />
          <FormInputField
            label={t('admin:docType.fixed')}
            description={t('admin:docType.fixedDescription')}
            type={'toggle'}
            onChange={(e) => {
              setState((fs) => {
                const copy = cloneDeep(fs);
                copy.isFixed = e;
                return copy;
              });
            }}
            value={state?.isFixed ?? false}
          />
          <FormInputField
            testId={'doctype-type-dropdown'}
            type={'dropdown'}
            dropdownOptions={validTopologyTypes}
            value={validTopologyTypes.find((ett) => ett.value === state.topologyType)}
            defaultDropdownOption={validTopologyTypes.find((ett) => ett.value === initialState?.topologyType)}
            label={t('admin:docType.type')}
            description={t('admin:docType.typeDescription')}
            onChange={(e) => {
              setState((fs) => {
                const copy = cloneDeep(fs);
                copy.topologyType = e.value;
                return copy;
              });
            }}
          />
        </FormSection>

        <FormSection hidden={isMultiEdit} title={t('admin:docType.promptConfig.title')}>
          <FormInputField
            type={'text'}
            layout={'vertical-large'}
            label={t('admin:docType.promptConfig.description')}
            description={t('admin:docType.promptConfig.descriptionDescription')}
            value={state.promptConfig?.description ?? ''}
            onChange={(e) => handleInput(e, 'promptConfig.description')}
          />
        </FormSection>
        {initialState?.id !== '@PB_NOTYPE' && (
          <>
            <FormSection hidden={initialState?.id === '@PB_NOTYPE'} title={t('admin:docType.automation')}>
              <FormInputField
                hidden={inboxMasterdataTables?.length === 0}
                type={'toggle'}
                description={t('admin:docType.masterdataCheckDescription')}
                label={t('admin:docType.masterdataCheck')}
                onChange={() => {
                  setState((fs) => {
                    const copy = cloneDeep(fs);
                    const current = state?.approvalChecks['masterdata'];
                    copy.approvalChecks['masterdata'] = handleIndeterminate(
                      current,
                      initialState?.approvalChecks['masterdata']
                    );
                    return copy;
                  });
                }}
                value={state?.approvalChecks['masterdata']}
                indeterminate={isMultiEdit && state?.approvalChecks['masterdata'] === undefined}
              />
              <FormInputField
                label={t('admin:docType.autoApprove')}
                description={t('admin:docType.autoApproveDescription')}
                type={'toggle'}
                onChange={() => {
                  setState((fs) => {
                    const copy = cloneDeep(fs);
                    const current = state?.autoApprove;
                    copy.autoApprove = handleIndeterminate(current, initialState.autoApprove);
                    return copy;
                  });
                }}
                value={state?.autoApprove}
                indeterminate={isMultiEdit && state?.autoApprove === undefined}
              />
              <FormInputField
                testId={'doctype-approval-threshold-input'}
                label={t('admin:docType.approvalThreshold')}
                description={t('admin:docType.approvalThresholdDescription')}
                type={'number'}
                numberInputOptions={{ label: '%', step: 0.1 }}
                value={state?.approvalThreshold}
                placeholder="??"
                onChange={(e) => {
                  if (e === '') {
                    handleInput(undefined, 'approvalThreshold');
                    return;
                  }
                  let value = parseFloat(e);
                  if (value > 100) value = 100;
                  handleInput(value, 'approvalThreshold');
                }}
              />
              <FormInputField
                testId={'doctype-ocr-threshold-input'}
                label={t('admin:docType.ocrThreshold')}
                description={t('admin:docType.ocrThresholdDescription')}
                type={'number'}
                numberInputOptions={{ label: '%', step: 0.1 }}
                value={state?.ocrThreshold}
                placeholder="??"
                onChange={(e) => {
                  if (e === '') {
                    handleInput(undefined, 'ocrThreshold');
                    return;
                  }
                  let value = parseFloat(e);
                  if (value > 100) value = 100;
                  handleInput(value, 'ocrThreshold');
                }}
              />
              <FormInputField
                label={t('admin:docType.ageThreshold')}
                description={t('admin:docType.ageThresholdDescription')}
                type={'number'}
                numberInputOptions={{ label: 'days', step: 1 }}
                value={state?.ageThreshold}
                placeholder="?"
                onChange={(e) => handleInput(parseFloat(e), 'ageThreshold')}
              />
            </FormSection>

            <FormSection
              add={
                isMultiEdit && {
                  label: hasMultiEditMetadataState ? 'Clear Existing' : 'Undo Changes',
                  onClick: () => {
                    if (hasMultiEditMetadataState) {
                      setState((state) => {
                        const clone = { ...state };
                        clone.metadataKeys = [];
                        return clone;
                      });
                    } else {
                      setState((state) => {
                        const clone = { ...state };
                        clone.metadataKeys = initialState.metadataKeys;
                        return clone;
                      });
                    }
                  },
                  icon: hasMultiEditMetadataState ? <TrashIcon /> : <ReloadIcon />,
                }
              }
              title={t('admin:docType.metadata')}
            >
              <div
                className={clsx(s.item, {
                  [s.item__vertical]: state.metadataKeys != undefined || !isMultiEdit,
                })}
              >
                <div className={s.item_text}>
                  <h4>{t('admin:docType.metadataKeys')}</h4>
                  <p>{t('admin:docType.metadataKeysDescription')}</p>
                </div>
                {hasMultiEditMetadataState && state.metadataKeys == undefined && (
                  <div className={s.item_fields}>
                    <div className={s.item_field} style={{ fontStyle: 'italic', cursor: 'default' }}>
                      Multiple Existing
                    </div>
                  </div>
                )}
                {/*{(!hasMultiEditFieldState || !isMultiEdit) && (*/}
                {/*    <div data-testid="doctype-fieldtype-section" className={s.item_action}>*/}
                {/*      {(!hasMultiEditFieldState || isEqual(state.entityTypes, [])) &&*/}
                {/*          state?.entityTypes?.length !== inboxEntityTypes?.length && (*/}
                {(!hasMultiEditMetadataState ||
                  !isMultiEdit ||
                  (hasMultiEditMetadataState && state?.metadataKeys?.length > 0)) && (
                  <div data-testid="doctype-metadata-section" className={s.item_action}>
                    {(!hasMultiEditMetadataState || isEqual(state.metadataKeys, [])) &&
                      state?.metadataKeys?.length !== inboxMetadataTypes?.length && (
                        <button
                          data-testid={'doctype-metadata-add'}
                          onClick={() =>
                            showDialog(
                              <AdminMultiSelectDialog
                                title={t('admin:inboxes.sections.metadataTypeSearch')}
                                description={t('admin:docType.metaSearchDescription')}
                                handleCheckTypes={(changes) => handleCheckMetadata(changes.map((e) => e.id))}
                                selectedTypes={state?.metadataKeys}
                                detailedList={inboxMetadataTypes}
                              />
                            )
                          }
                          type={'button'}
                          className={clsx(s.top_button, s.item_add)}
                        >
                          <PlusIcon /> {t('admin:inboxes.sections.addNew')}
                        </button>
                      )}
                    <div className={s.row_list} style={{ marginTop: 20 }}>
                      {state.metadataKeys?.map((key) => {
                        return (
                          <AdminDocTypeMetadataRow
                            handleDelete={() => handleRemoveMetadata(key.id)}
                            handleChangeValue={(value) => handleSetMetadataValue(key.id, value)}
                            key={key.id + doctypeId}
                            item={key}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </FormSection>
            <FormSection
              hidden={state.topologyType !== 'bundle'}
              add={
                isMultiEdit && {
                  // (initialState.entityTypes == undefined || !isEqual(state.entityTypes, [])) && {
                  label: hasMultiEditFieldState ? 'Clear Existing' : 'Undo Changes',
                  onClick: () => {
                    if (hasMultiEditFieldState) {
                      setState((state) => {
                        const clone = { ...state };
                        clone.entityTypes = [];
                        clone.categories = [];
                        return clone;
                      });
                    } else {
                      setState((state) => {
                        const clone = { ...state };
                        clone.entityTypes = initialState.entityTypes;
                        clone.categories = initialState.categories;
                        return clone;
                      });
                    }
                  },
                  icon: hasMultiEditFieldState ? <TrashIcon /> : <ReloadIcon />,
                }
              }
              title={t('admin:docType.fields')}
            >
              <div
                className={clsx(s.item, {
                  [s.item__vertical]: state.entityTypes != undefined || !isMultiEdit,
                })}
              >
                <div className={s.item_text}>
                  <h4>{t('admin:docType.entityTypes')}</h4>
                  <p>{t('admin:docType.entityTypesDescription')}</p>
                </div>
                {hasMultiEditFieldState && state.entityTypes == undefined && (
                  <div className={s.item_fields}>
                    <div className={s.item_field} style={{ fontStyle: 'italic', cursor: 'default' }}>
                      Multiple Existing
                    </div>
                  </div>
                )}
                {(!hasMultiEditFieldState ||
                  !isMultiEdit ||
                  (hasMultiEditFieldState && state?.entityTypes?.length > 0)) && (
                  <div data-testid="doctype-fieldtype-section" className={s.item_action}>
                    {(!hasMultiEditFieldState || isEqual(state.entityTypes, [])) &&
                      state?.entityTypes?.length !== inboxEntityTypes?.length && (
                        <button
                          data-testid={'doctype-fieldtype-add'}
                          onClick={() =>
                            showDialog(
                              <AdminMultiSelectDialog
                                title={t('admin:inboxes.sections.fieldTypeSearch')}
                                description={t('admin:docType.fieldSearchDescription')}
                                handleCheckTypes={(changes) => handleCheckTypes(changes.map((e) => e.id))}
                                selectedTypes={state?.entityTypes}
                                detailedList={inboxEntityTypes as MultiSelectDetailsType[]}
                              />
                            )
                          }
                          type={'button'}
                          className={clsx(s.top_button, s.item_add)}
                        >
                          <PlusIcon /> {t('admin:inboxes.sections.addNew')}
                        </button>
                      )}
                    <AdminSortableFields
                      inboxEntityTypes={inboxEntityTypes}
                      entityTypes={state?.entityTypes}
                      setItems={handleSetEntityTypes}
                      handleDelete={handleRemoveEntityType}
                    />
                  </div>
                )}
              </div>
              {(!hasMultiEditFieldState || !isMultiEdit) && (
                <div className={clsx(s.item, s.item__vertical)}>
                  <div className={s.item_text}>
                    <h4>{t('admin:docType.categories')}</h4>
                    <p>{t('admin:docType.categoriesDescription')}</p>
                  </div>
                  <div className={s.item_action}>
                    <button
                      data-testid={'doctype-category-add'}
                      onClick={handleAddCategory}
                      type={'button'}
                      className={clsx(s.top_button, s.item_add)}
                    >
                      <PlusIcon /> {t('admin:inboxes.sections.addNew')}
                    </button>
                    <AdminSortableCategories
                      items={state?.categories ?? []}
                      handleDeleteCategory={handleDeleteCategory}
                      handleUpdateCategory={handleUpdateCategory}
                      entityOptionsList={filteredEntities}
                      setCategories={handleSetCategories}
                    />
                  </div>
                </div>
              )}
            </FormSection>

            <FormSection
              noStyle
              scroll
              title={t('admin:docType.docSubTypes')}
              hidden={isMultiEdit}
              search={{ onChange: handleSubtypeInput, placeholder: t('admin:docType.docSubTypesSearch') }}
              add={{
                testId: 'doctype-subtype-add',
                onClick: () => {
                  dispatch(adminSlice.actions.setNewTempDocType(state));
                  navigate(`/admin/inboxes/${inboxId}/doctypes/${doctypeId}/subtypes/new`);
                },
                label: t('admin:inboxes.sections.addNew'),
              }}
            >
              <div className={clsx(s.row_list)} data-testid={'doctype-subtype-section'}>
                {subtypeResults?.length === 0 && <div>{t('admin:docType.docSubTypesNotFound')}</div>}
                {[...subtypeResults]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((subType) => {
                    return (
                      <AdminItemRow
                        handleNav={() => {
                          dispatch(adminSlice.actions.setNewTempDocType(state));
                          navigate(`/admin/inboxes/${inboxId}/doctypes/${doctypeId}/subtypes/${subType.id}`);
                        }}
                        key={subType.id + doctypeId}
                        subType={subType}
                        title={subType.name}
                        handleDelete={() => handleDeleteSubtype(subType)}
                      />
                    );
                  })}
              </div>
            </FormSection>

            <FormSection hidden={doctypeId === 'new'} title={t('admin:docType.dangerZone')}>
              <FormInputField
                type={'button'}
                buttonOptions={{
                  type: 'error',
                  text: t('admin:docType.deleteType'),
                  onClick: handleDelete,
                }}
                label={t('admin:docType.deleteType')}
                description={t('admin:docType.deleteTypeDescription')}
              />
            </FormSection>
          </>
        )}
      </div>
    </form>
  );
};
export default AdminInboxesDoctypeEdit;
