import Tooltip from '@components/shared/tooltip/Tooltip';
import { usePrevious } from '@shared/helpers/helpers.ts';
import useConsole from '@shared/hooks/useConsole.tsx';
import { PaginatedToken } from '@shared/models/document';
import { pageIndexToPageNoSelector } from '@shared/store/documentSlice.ts';
import { labelerSlice } from '@shared/store/labelerSlice';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler.module.scss';
import { ReactComponent as ChevronDoubleLeft } from '@svg/chevron-double-left.svg';
import { ReactComponent as ChrevronDown } from '@svg/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '@svg/chevron-left.svg';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as DownloadIcon } from '@svg/download.svg';
import { ReactComponent as FitHorizontalIcon } from '@svg/fit-horizontal-icon.svg';
import { ReactComponent as FitVerticalIcon } from '@svg/fit-vertical-icon.svg';
import { ReactComponent as ImagePlaceholderIcon } from '@svg/image-placeholder.svg';
import { ReactComponent as MinusIcon } from '@svg/minus-icon.svg';
import { ReactComponent as PlusIcon } from '@svg/plus-large-icon.svg';
import { ReactComponent as RotateIcon } from '@svg/rotate-icon.svg';
import { ReactComponent as SearchIcon } from '@svg/search-icon.svg';
import { Pulsar } from '@uiball/loaders';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';

interface Props {
  innerHeight: number;
  isMouseDown: boolean;
  isSelectionVisible: boolean;
  handleSetPage: (newPageIndex: number) => void;
  handleScale: (isPositive: boolean) => void;
  handleFitToScreen?: (fitType: 'all' | 'width' | 'height') => void;
  handleRotate?: () => void;
  handleDownload?: () => void;
  handleSetThumbsVisible?: () => void;
  setSearchQuery: (query: string) => void;
  searchQuery: string;
  paginatedTokens: PaginatedToken[];
  setActiveToken: (token: PaginatedToken) => void;
}

const LabelerPagination: React.FC<Props> = ({
  innerHeight,
  isMouseDown,
  handleSetPage,
  isSelectionVisible,
  handleScale,
  handleFitToScreen,
  handleRotate,
  handleDownload,
  handleSetThumbsVisible,
  searchQuery,
  setSearchQuery,
  paginatedTokens,
  setActiveToken,
}) => {
  const activePageNo = useSelector((state) => state.labeler.activePageNo);
  const { documentDownload } = useSelector((state) => state.inbox.currentInbox.settings);
  const isThumbsLoading = useSelector((state) => state.document.isThumbsLoading);
  const pageIndexMap = useSelector(pageIndexToPageNoSelector);

  const [pageInputValue, setPageInputValue] = useState<string>('1');
  const [isFitWidth, setIsFitWidth] = useState(false);
  const [activeTokenIndex, setActiveTokenIndex] = useState<number>(0);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const inputRef = useRef();
  const matches = useMemo(() => {
    if (searchQuery)
      return paginatedTokens?.filter((e) => e.text.toLowerCase().includes(searchQuery.toLowerCase())) ?? [];
    return [];
  }, [paginatedTokens, searchQuery]);

  useEffect(() => {
    if (matches.length > 0) {
      setActiveTokenIndex(0);
    }
  }, [matches]);

  useEffect(() => {
    setActiveToken(matches[activeTokenIndex]);
  }, [activeTokenIndex, matches, setActiveToken]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activePageNo !== null) {
      if (pageIndexMap.findIndex((e) => e === activePageNo) !== -1) {
        setPageInputValue((cur) => {
          const update = `${pageIndexMap.findIndex((e) => e === activePageNo) + 1}`;
          if (cur !== update) {
            dispatch(labelerSlice.actions.setTempEntity(null));
            return update;
          }
          return cur;
        });
      }
    }
  }, [activePageNo, dispatch, pageIndexMap]);
  const prevPageInputValue = usePrevious(pageInputValue);

  useEffect(() => {
    if (prevPageInputValue == pageInputValue) return;
    if (pageInputValue == null) return;
    handleSetPage(pageIndexMap[parseInt(pageInputValue) - 1]);
  }, [handleSetPage, pageIndexMap, pageInputValue, prevPageInputValue]);

  const handleSearch = (e: KeyboardEvent) => {
    if ((e.ctrlKey && e.code === 'KeyF') || (e.code === 'KeyF' && e.metaKey)) {
      e.preventDefault();
      setIsSearchActive(true);
      if (inputRef.current) {
        const input = inputRef.current as HTMLInputElement;
        input.focus();
      }
    }
  };
  useEffect(() => {
    if (!isSearchActive) {
      setSearchQuery('');
    }
  }, [isSearchActive, setSearchQuery]);

  useEffect(() => {
    document.addEventListener('keydown', handleSearch);
    return () => {
      document.removeEventListener('keydown', handleSearch);
    };
  }, []);

  const handleTokenNav = (isNext: boolean) => {
    let newValue;
    if (isNext) {
      if (activeTokenIndex === matches.length - 1) {
        newValue = 0;
      } else {
        newValue = activeTokenIndex + 1;
      }
    } else {
      if (activeTokenIndex === 0) {
        newValue = matches.length - 1;
      } else {
        newValue = activeTokenIndex - 1;
      }
    }
    setActiveTokenIndex(newValue);
  };
  const handleChangeBack = () => {
    const currentMappedIndex = pageIndexMap.findIndex((e) => e === activePageNo);
    const nextPageNo = pageIndexMap[currentMappedIndex - 1];
    handleSetPage(nextPageNo);
  };
  const handleChangeForward = () => {
    const currentMappedIndex = pageIndexMap.findIndex((e) => e === activePageNo);
    const nextPageNo = pageIndexMap[currentMappedIndex + 1];
    handleSetPage(nextPageNo);
  };
  useConsole(activePageNo);
  const searchText = useMemo(() => {
    if (searchQuery == null || searchQuery === '') {
      return '';
    }
    if (matches.length === 0) return 'No matches';
    return `${activeTokenIndex + 1}/${matches.length}`;
  }, [activeTokenIndex, matches, searchQuery]);

  const wrapperStyle = useMemo(() => {
    let style = {
      top: innerHeight ? innerHeight + 75 : 2000,
      transition: 'transform 0.2s',
    } as React.CSSProperties;
    if (isHidden) {
      style = {
        ...style,
        transform: 'translateY(90px)',
      };
    }

    return style;
  }, [isHidden, innerHeight]);

  return (
    <div
      className={clsx(s.document_page_actions_wrapper, {
        [s.document_page_actions_wrapper__faded]: isMouseDown && isSelectionVisible,
      })}
      style={wrapperStyle}
    >
      <button onClick={() => setIsHidden(!isHidden)} className={clsx(s.hide, { [s.hide__hidden]: isHidden })}>
        <ChrevronDown />
      </button>
      <div className={clsx(s.page_search, { [s.page_search__active]: isSearchActive })}>
        <div className={s.input_wrapper}>
          <input
            value={searchQuery ?? ''}
            ref={inputRef}
            className={s.input}
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className={s.counter}>{searchText}</div>
        </div>
        <button className={s.counter_nav} onClick={() => handleTokenNav(false)}>
          <ChrevronDown style={{ transform: 'rotate(180deg)' }} />
        </button>
        <button className={s.counter_nav} onClick={() => handleTokenNav(true)}>
          <ChrevronDown />
        </button>
        <button
          onClick={() => setIsSearchActive(false)}
          className={clsx(s.counter_nav, s.counter_nav__delete)}
        >
          <CrossIcon />
        </button>
      </div>
      <div
        data-tour="pagination"
        className={clsx(s.document_page_actions, {
          [s.document_page_actions__faded]: isMouseDown && isSelectionVisible,
        })}
      >
        <button
          disabled={activePageNo === 1}
          className={s.document_page_nav}
          onClick={() => handleSetPage(1)}
        >
          <ChevronDoubleLeft />
        </button>
        <button
          disabled={activePageNo === 1}
          className={s.document_page_nav}
          onClick={() => handleChangeBack()}
        >
          <ChevronLeft />
        </button>

        <div className={s.document_page_action}>
          <input
            style={{
              width: pageInputValue ? pageInputValue.toString().length * 7 + 6 : 13,
            }}
            max={pageIndexMap.length}
            type="number"
            value={pageInputValue}
            onKeyDown={(e) => {
              if (e.key === 'enter') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onChange={(event) => {
              if (parseInt(event.target.value) >= 1 && parseInt(event.target.value) <= pageIndexMap.length) {
                setPageInputValue(event.target.value);
              } else if (event.target.value === '') {
                setPageInputValue('');
              } else {
                // setPageInputValue(`${activePageNo}`);
              }
              return false;
            }}
          />
          /{pageIndexMap.length ?? '?'}
        </div>

        <button
          disabled={pageIndexMap.findIndex((e) => e === activePageNo) + 1 === pageIndexMap.length}
          className={s.document_page_nav}
          onClick={() => handleChangeForward()}
        >
          <ChevronLeft style={{ transform: 'rotate(180deg)' }} />
        </button>
        <button
          disabled={activePageNo === pageIndexMap[pageIndexMap.length - 1]}
          className={s.document_page_nav}
          onClick={() => {
            handleSetPage(pageIndexMap[pageIndexMap.length - 1]);
          }}
        >
          <ChevronDoubleLeft style={{ transform: 'rotate(180deg)' }} />
        </button>
        <div className={s.document_page_divider} />
        <Tooltip delay={100} position={'top'} content={isFitWidth ? 'Fit Width' : 'Fit Height'}>
          <button
            data-testid={'fit-document'}
            className={clsx(s.document_page_nav)}
            onClick={() => {
              if (isFitWidth) {
                handleFitToScreen('height');
                setIsFitWidth(false);
              } else {
                handleFitToScreen('width');
                setIsFitWidth(true);
              }
            }}
          >
            {isFitWidth ? <FitHorizontalIcon /> : <FitVerticalIcon />}
          </button>
        </Tooltip>
        <Tooltip delay={100} position={'top'} content={'Rotate 90 degrees'}>
          <button className={clsx(s.document_page_nav)} onClick={handleRotate}>
            <RotateIcon />
          </button>
        </Tooltip>
        <Tooltip delay={100} position={'top'} content={'Zoom (Ctrl + scroll)'}>
          <button className={clsx(s.document_page_nav)} onClick={() => handleScale(true)}>
            <PlusIcon />
          </button>
        </Tooltip>
        <Tooltip delay={100} position={'top'} content={'Zoom (Ctrl + scroll)'}>
          <button className={clsx(s.document_page_nav)} onClick={() => handleScale(false)}>
            <MinusIcon />
          </button>
        </Tooltip>

        <div className={s.document_page_divider} />

        {documentDownload && (
          <Tooltip delay={100} position={'top'} content={'Download Original'}>
            <button className={clsx(s.document_page_nav)} onClick={() => handleDownload()}>
              <DownloadIcon />
            </button>
          </Tooltip>
        )}

        {isThumbsLoading ? (
          <div className={s.document_page_nav}>
            <div>
              <Pulsar size={15} />
            </div>
          </div>
        ) : (
          <Tooltip delay={100} position={'top'} content={'Show / Hide Thumbnails'}>
            <button className={s.document_page_nav} onClick={() => handleSetThumbsVisible()}>
              <ImagePlaceholderIcon />
            </button>
          </Tooltip>
        )}
        <Tooltip delay={100} position={'top'} content={'Document Search'}>
          <button className={clsx(s.document_page_nav)} onClick={() => setIsSearchActive(true)}>
            <SearchIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default LabelerPagination;
