import AdminInputDialog from '@components/admin/components/AdminInputDialog.tsx';
import AdminSortableList from '@components/admin/components/AdminSortableList.tsx';
import FormBodyHeader from '@components/admin/components/form/FormBodyHeader.tsx';
import { FormHeaderNav } from '@components/admin/components/form/FormHeaderNav.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import { IClientMetadata, metadataClientToRaw } from '@shared/helpers/converters/metadata.ts';
import useChangeTracker, { ChangeSaveCallback } from '@shared/hooks/useChangeTracker.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import { deleteMetadataTypes, patchMetadataType, postMetadataType } from '@shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const AdminInboxesMetadataEdit: React.FC = () => {
  const { inboxId, metadataId } = useParams();
  const inboxMetadataTypes = useSelector((state) => state.admin.inboxMetadataTypes);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const navigate = useNavigate();

  const activeMetadataType = useMemo(() => {
    return inboxMetadataTypes?.find((id) => id.id === metadataId);
  }, [metadataId, inboxMetadataTypes]);

  const handleDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          dispatch(deleteMetadataTypes([metadataId]));
          navigate(`/admin/inboxes/${inboxId}/metadata`);
        }}
        text={t('admin:inboxes.sections.metadataTypeDelete')}
      />
    );
  };
  const handleNavBack = () => {
    if (hasChanges) {
      showDialog(
        <ConfirmationDialog
          confirmAction={() => {
            save(null);
            navigate(`/admin/inboxes/${inboxId}/metadata`);
          }}
          cancelAction={() => navigate(`/admin/inboxes/${inboxId}/metadata`)}
          confirmText={t('document:dialog.save')}
          cancelText={t('document:dialog.discard')}
          dialogType={'confirmation'}
          title={t('admin:unsavedChanges.title')}
          text={t('admin:unsavedChanges.description')}
        />
      );
    } else {
      navigate(`/admin/inboxes/${inboxId}/metadata`);
    }
  };

  const handleSave: ChangeSaveCallback<IClientMetadata> = async () => {
    const item = metadataClientToRaw(state);
    if (metadataId !== 'new') {
      return patchMetadataType(inboxId, metadataId, item).then(() => {
        navigate(`/admin/inboxes/${inboxId}/metadata`);
      });
    } else {
      return postMetadataType(inboxId, item).then(() => {
        navigate(`/admin/inboxes/${inboxId}/metadata`);
      });
    }
  };

  const initialState = useMemo(() => {
    if (activeMetadataType) {
      const item = { ...activeMetadataType };
      if (item.providerId === '') {
        item.providerId = item.id;
      }
      return item;
    }
    const obj: IClientMetadata = {
      name: '',
      id: '',
      isSensitive: false,
      isHidden: false,
      useTopologyPartsToAggregate: true,
    };

    return obj;
  }, [activeMetadataType]);

  const metadataTypes = [
    { value: 'text', label: 'Text' },
    { value: 'choice', label: 'Options' },
  ];

  const { state, save, saving, handleInput, error, hasChanges, setState } = useChangeTracker<IClientMetadata>(
    initialState,
    handleSave
  );
  const mappedOptions = state?.options?.map((option) => {
    return { id: option.value, name: option.value };
  });

  const nextField = useMemo(() => {
    if (metadataId && inboxMetadataTypes) {
      const sorted = [...inboxMetadataTypes].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === metadataId);
      return sorted[index + 1];
    }
    return null;
  }, [metadataId, inboxMetadataTypes]);

  const prevField = useMemo(() => {
    if (metadataId && inboxMetadataTypes) {
      const sorted = [...inboxMetadataTypes].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      const index = sorted.findIndex((e) => e.id === metadataId);
      return sorted[index - 1];
    }
    return null;
  }, [metadataId, inboxMetadataTypes]);

  const handleNavNextField = () => {
    if (nextField) {
      navigate(`/admin/inboxes/${inboxId}/metadata/${nextField.id}`);
    }
  };

  const handleNavPrevField = () => {
    if (prevField) {
      navigate(`/admin/inboxes/${inboxId}/metadata/${prevField.id}`);
    }
  };

  return (
    <form onSubmit={save} className={s.form_body}>
      <FormHeaderNav
        onClick={handleNavBack}
        label={t('admin:page.backToOverview')}
        navOptions={
          metadataId === 'new'
            ? {}
            : {
                prev: {
                  label: t('admin:page.previous'),
                  onClick: handleNavPrevField,
                  active: !!prevField,
                },
                next: {
                  label: t('admin:page.next'),
                  onClick: handleNavNextField,
                  active: !!nextField,
                },
              }
        }
      />
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        errorMessage={error ? t('admin:metadataType.alreadyExists') : ''}
        title={activeMetadataType?.name ?? t('admin:metadataType.newType')}
      />
      <div className={s.sections}>
        <FormSection title={t('admin:metadataType.config')}>
          <FormInputField
            testId={'metadata-name-input'}
            type={'text'}
            description={t('admin:metadataType.nameDescription')}
            label={t('admin:metadataType.name')}
            required
            onChange={(e) => handleInput(e, 'name')}
            value={state?.name ?? ''}
          />
          <FormInputField
            hidden={metadataId === 'new'}
            disabled
            isCopyField
            testId={'metadata-id-input'}
            type={'text'}
            description={t('admin:metadataType.idDescription')}
            label={t('admin:metadataType.id')}
            value={state?.id ?? ''}
          />
          <FormInputField
            testId={'metadata-provider-id-input'}
            type={'text'}
            description={t('admin:metadataType.providerIdDescription')}
            label={t('admin:metadataType.providerId')}
            onChange={(e) => handleInput(e, 'providerId')}
            value={state?.providerId ?? ''}
          />
          <FormInputField
            value={state?.isSensitive}
            type={'toggle'}
            label={t('admin:metadataType.sensitive')}
            description={t('admin:metadataType.sensitiveDescription')}
            onChange={(e) => handleInput(e, 'isSensitive')}
          />
          <FormInputField
            value={state?.isHidden}
            type={'toggle'}
            label={t('admin:metadataType.hidden')}
            description={t('admin:metadataType.hiddenDescription')}
            onChange={(e) => handleInput(e, 'isHidden')}
          />
        </FormSection>
        <FormSection title={t('admin:actionType.values')}>
          <FormInputField
            type={'dropdown'}
            label={t('admin:actionType.valueType')}
            description={t('admin:actionType.valueTypeDescription')}
            value={
              state.options
                ? metadataTypes.find((e) => e.value === 'choice')
                : metadataTypes.find((e) => e.value === 'text')
            }
            onChange={(val) => {
              if (val.value === 'choice') {
                setState({ ...state, options: [] });
              } else {
                setState({ ...state, options: null });
              }
            }}
            dropdownOptions={metadataTypes}
          />

          {state.options && (
            <>
              <FormInputField
                value={state?.useTopologyPartsToAggregate}
                type={'toggle'}
                label={t('admin:metadataType.useTopologyPartsToAggregate')}
                description={t('admin:metadataType.useTopologyPartsToAggregateDescription')}
                onChange={(e) => handleInput(e, 'useTopologyPartsToAggregate')}
              />
              <FormInputField
                label={t('admin:metadataType.values.title')}
                description={t('admin:metadataType.values.description')}
                type={'button'}
                buttonOptions={{
                  type: 'normal',
                  text: (
                    <>
                      <PlusIcon /> {t('admin:metadataType.values.addNew')}
                    </>
                  ),
                  onClick: () => {
                    showDialog(
                      <AdminInputDialog
                        title={t('admin:metadataType.values.addTitle')}
                        description={t('admin:metadataType.values.addDescription')}
                        handleAddItem={(e) =>
                          setState({ ...state, options: [...state.options, { value: e }] })
                        }
                        currentList={state.options.map((e) => e.value)}
                      />
                    );
                  },
                }}
              >
                <div className={s.sortable_section}>
                  <AdminSortableList
                    detailedItems={mappedOptions}
                    items={state.options.map((e) => e.value)}
                    setItems={(e) => setState({ ...state, options: e.map((eo) => ({ value: eo })) })}
                    handleRemoveType={(id) => {
                      setState({ ...state, options: state.options?.filter((option) => option.value !== id) });
                    }}
                  />
                </div>
              </FormInputField>
            </>
          )}
        </FormSection>
        <FormSection hidden={metadataId === 'new'} title={t('admin:metadataType.dangerZone')}>
          <FormInputField
            type={'button'}
            buttonOptions={{
              type: 'error',
              text: t('admin:metadataType.deleteType'),
              onClick: handleDelete,
            }}
            label={t('admin:metadataType.deleteType')}
            description={t('admin:metadataType.deleteTypeDescription')}
          />
        </FormSection>
      </div>
    </form>
  );
};

export default AdminInboxesMetadataEdit;
