import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClientFieldType } from '@shared/helpers/converters/fieldtype.ts';
import {
  defaultClientInbox,
  IClientInbox,
  inboxRawToClient,
  IRawInbox,
} from '@shared/helpers/converters/inbox.ts';
import { metadataRawToClient } from '@shared/helpers/converters/metadata.ts';
import { camelToSnakeCase, getUserToken, snakeToCamelCase } from '@shared/helpers/helpers';
import {
  ActionType,
  CopyStructure,
  DocTypeCategory,
  DocumentApprovalCheck,
  DocumentDetails,
  MasterDataAnalytics,
  MasterDataVersion,
  TagType,
} from '@shared/models/document';
import {
  ApprovalCheckType,
  DocTypeSettings,
  DocTypeSummary,
  DocumentCount,
  DocumentListOptions,
  DocumentListState,
  SidebarData,
  SidebarType,
} from '@shared/models/inbox';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { off, onValue, ref } from 'firebase/database';
import {
  collection,
  doc,
  endAt,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { subDays } from 'rsuite/esm/utils/dateUtils';
import documentSlice, { getCopy } from './documentSlice';
import { settingsSlice } from './settingsSlice';
import { api, db, realtime } from './setup/firebase-setup';
import { AppThunk, RootState } from './store';
import { subsSlice } from './subsSlice';

interface InboxState {
  activeDocumentType?: DocTypeSummary;
  sideBarData?: SidebarData;
  typeMap?: { normal: Map<string, string>; reverse: Map<string, string> };
  currentPageIndex: number;
  documentsLoading: boolean;
  currentDocumentList?: DocumentDetails[];
  documentListOptions?: DocumentListOptions;
  documentListState?: DocumentListState;
  documentCounts?: DocumentCount[];
  pendingInboxDocuments?: string[];
  pendingRouterDocuments?: string[];
  masterDataMappings?: Record<string, MasterDataVersion>;
  activeTagCounts?: Record<string, number>;
  currentInbox?: IClientInbox;
  historicalDocumentCount?: number;
}

const initialState: InboxState = {
  activeDocumentType: { docTypeId: null },
  currentPageIndex: 0,
  pendingInboxDocuments: [],
  pendingRouterDocuments: [],
  typeMap: { normal: new Map<string, string>(), reverse: new Map<string, string>() },
  documentsLoading: false,
  documentListState: {},
  currentInbox: defaultClientInbox,
  documentListOptions: {
    searchTerm: '',
    inboxId: null,
    navDirection: null,
    subTypeId: undefined,
    docTypeId: '',
    pageSize: 10,
    sortBy: 'upload_time',
    isSortDescending: false,
    // dateRange: [subDays(new Date(), 14), new Date()],
  },
  historicalDocumentCount: 0,
};

let unsubDocumentList;

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setActiveDocumentType: (state, action: PayloadAction<DocTypeSummary>) => {
      state.activeDocumentType = action.payload;
    },

    setTypeMap: (
      state,
      action: PayloadAction<{ normal: Map<string, string>; reverse: Map<string, string> }>
    ) => {
      state.typeMap = action.payload;
    },

    setCurrentPageIndex: (state, action: PayloadAction<number>) => {
      state.currentPageIndex = action.payload;
    },

    setPendingInboxDocuments: (state, action: PayloadAction<string[]>) => {
      state.pendingInboxDocuments = action.payload;
    },
    setPendingRouterDocuments: (state, action: PayloadAction<string[]>) => {
      state.pendingRouterDocuments = action.payload;
    },
    setHistoricalDocumentCount: (state, action: PayloadAction<number>) => {
      state.historicalDocumentCount = action.payload;
    },

    setCurrentDocumentList: (state, action: PayloadAction<DocumentDetails[]>) => {
      state.currentDocumentList = action.payload;
    },

    setSideBarData: (state, action: PayloadAction<SidebarData>) => {
      state.sideBarData = action.payload;
    },

    setDocumentCounts: (state, action: PayloadAction<DocumentCount[]>) => {
      state.documentCounts = action.payload;
    },

    setCurrentInbox: (state, action: PayloadAction<IClientInbox>) => {
      state.currentInbox = action.payload;
    },

    // setSettings: (state, action: PayloadAction<InboxSettings>) => {
    //   state.settings = action.payload;
    // },

    setDocumentsLoading: (state, action: PayloadAction<boolean>) => {
      state.documentsLoading = action.payload;
    },

    setDocumentListOptions: (state, action: PayloadAction<DocumentListOptions>) => {
      state.documentListOptions = action.payload;
    },

    patchDocumentListOptions: (state, action: PayloadAction<Partial<DocumentListOptions>>) => {
      state.documentListOptions = { ...state.documentListOptions, ...action.payload };
    },

    setDocumentListState: (state, action: PayloadAction<DocumentListState>) => {
      state.documentListState = action.payload;
    },

    setMasterDataMappings: (state, action: PayloadAction<Record<string, MasterDataVersion>>) => {
      state.masterDataMappings = action.payload;
    },
    setActiveTagCounts: (state, action: PayloadAction<Record<string, number>>) => {
      state.activeTagCounts = action.payload;
    },
  },
});

export const calculateNoTagCount = (activeTagCounts: Record<string, number>) => {
  if (activeTagCounts) {
    return activeTagCounts['@NO_TAG'];
  }
  return 0;
};

const getDocumentCountsSelector = (state: RootState): SidebarData => state.inbox.sideBarData;
const getDocumentListOptionsSelector = (state: RootState): DocumentListOptions =>
  state.inbox.documentListOptions;
const ActiveTagCountsSelector = (state: RootState): Record<string, number> => state.inbox.activeTagCounts;
const getActiveDocTypeSelector = (state: RootState): DocTypeSummary => state.inbox.activeDocumentType;

export const validTopologyTypesSelector = createSelector(
  [(state) => state.inbox.currentInbox],
  (currentInbox) => {
    if (currentInbox?.settings.mailroom) {
      return [
        { value: 'bundle', label: 'Bundle' },
        { value: 'document', label: 'Document' },
        { value: 'mail', label: 'Mail' },
      ];
    } else {
      return [
        { value: 'bundle', label: 'Bundle' },
        { value: 'document', label: 'Document' },
      ];
    }
  }
);

export const getActiveCountSelector = createSelector(
  [
    getDocumentCountsSelector,
    getActiveDocTypeSelector,
    getDocumentListOptionsSelector,
    ActiveTagCountsSelector,
  ],
  (documentCounts, activeDocType, docListOptions, activeTagCounts) => {
    const { activeTagId } = docListOptions ?? {};
    const hasActiveTags = activeTagCounts && Object.entries(activeTagCounts).length > 0;

    const getCountForType = (typeId: string, subTypeId?: string) => {
      let type = documentCounts?.types.find((type) => type.id === typeId);
      if (subTypeId) {
        type = type?.subTypes.find((subType) => subType.id === subTypeId);
      }
      return type?.count ?? 0;
    };

    if (hasActiveTags && activeTagId) {
      if (activeDocType.docTypeId === '') {
        return activeTagCounts[activeTagId];
      } else {
        return activeTagCounts[activeTagId];
      }
    } else if (documentCounts) {
      if (activeDocType.docTypeId === '') {
        return documentCounts.types.reduce((acc, type) => acc + type.count, 0);
      } else {
        return getCountForType(activeDocType.docTypeId, activeDocType.subTypeId);
      }
    }

    return 0;
  }
);
export const listenForActiveInbox =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;
    const activeInboxSub = getState().subs.activeInboxSub;
    if (activeInboxSub) activeInboxSub();

    const inboxRef = doc(db, `tenants/${tenantId}/inboxes/${inboxId}`);

    const inboxUnsub = onSnapshot(inboxRef, (doc) => {
      const data = doc.data();
      if (data === undefined) return;
      const inbox = inboxRawToClient({ id: doc.id, ...data } as IRawInbox);
      dispatch(inboxSlice.actions.setCurrentInbox(inbox));
    });
    dispatch(subsSlice.actions.setActiveInboxSub(inboxUnsub));
  };

export const setLockListener =
  (inboxId): AppThunk =>
  (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;

    const query = ref(realtime, `tenants/${tenantId}/inboxes/${inboxId}/lockers`);
    dispatch(documentSlice.actions.setLockListener(query));
    onValue(query, (res) => {
      if (res.val()) {
        dispatch(documentSlice.actions.setLockedList(res.val()));
      } else {
        dispatch(documentSlice.actions.setLockedList({}));
      }
    });
  };

export const clearLockListener = (): AppThunk => (_, getState) => {
  const lockListener = getState().document.lockListener;
  if (lockListener) {
    off(lockListener);
  }
};

export const documentConverter = {
  toFirestore() {
    return {};
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): DocumentDetails {
    const data = snapshot.data();
    const doc = {
      ...camelcaseKeys(data, { deep: true }),
      availableTime: data['available_time']?.toDate(),
      id: snapshot.id,
      providerDocumentId: data['provider_document_id'],
      docTypeId: data['doc_type_id'],
      docSubtypeId: data['doc_subtype_id'],
      lastOpenedDate: data['last_opened_date']?.toDate(),
      lastUpdatedDate: data['last_updated_date']?.toDate(),
      uploadTime: data['upload_time']?.toDate(),
      lastUserUpdateTime: data['last_user_update_time']?.toDate(),
      approvalChecks:
        typeof data['approval_checks'] === 'object' && !Array.isArray(data['approval_checks'])
          ? Object.entries(camelcaseKeys(data['approval_checks'], { deep: true })).map(([k, v]) => {
              return { id: k, ...(v as DocumentApprovalCheck) } as DocumentApprovalCheck;
            })
          : data['approval_checks']
          ? data['approval_checks'].map((e) => ({ ...e, id: snakeToCamelCase(e.id) }))
          : null,
      initialApprovalChecks:
        typeof data['initial_approval_checks'] === 'object' && !Array.isArray(data['initial_approval_checks'])
          ? Object.entries(camelcaseKeys(data['initial_approval_checks'], { deep: true })).map(([k, v]) => {
              return { id: k, ...(v as DocumentApprovalCheck) } as DocumentApprovalCheck;
            })
          : data['initial_approval_checks']
          ? data['initial_approval_checks'].map((e) => ({ ...e, id: snakeToCamelCase(e.id) }))
          : null,
      locker: data['locker']
        ? {
            ...camelcaseKeys(data.locker, { deep: true }),
            lastLockedTime: data.locker.last_locked_time ? data.locker.last_locked_time.toDate() : null,
          }
        : null,
    } as any;
    if (data['action']) {
      doc.action = {
        ...camelcaseKeys(data['action'], { deep: true }),
        timestamp: data['action']['timestamp']?.toDate(),
      };
    }

    return doc as DocumentDetails;
  },
};
export const elasticSortMap = {
  lastOpenedDate: 'last_opened_date',
  lastUpdatedDate: 'last_updated_date',
  actionDate: 'action.timestamp',
  actor: 'action.actor_email.keyword',
  tagTypeId: 'tag_type_id.keyword',
  docTypeId: 'doc_type_id.keyword',
  name: 'name.keyword',
};
export const fsSortMap = {
  lastOpenedDate: 'last_opened_date',
  lastUpdatedDate: 'last_updated_date',
  actionDate: 'action.timestamp',
  actor: 'action.actor_email',
  tagTypeId: 'tag_type_id',
  docTypeId: 'doc_type_id',
  name: 'name',
};
let unsubHistorical;
export const getHistoricalDocumentIds =
  (inboxId: string, page: number): AppThunk =>
  async (dispatch, getState) => {
    const {
      pageSize,
      docTypeId,
      subTypeId,
      activeTagId,
      sortBy,
      isSortDescending,
      searchTerm,
      dateRange,
      action,
      actorId,
      actorIdFilterMode,
    } = getState().inbox.documentListOptions;
    let sortByFiltered = sortBy;
    if (!elasticSortMap[sortBy]) sortByFiltered = 'actionDate';
    const tenantId = getState().tenant.tenantId;
    const params = {
      page_size: pageSize,
      page: page,
      sort_by: elasticSortMap[sortByFiltered],
      sort_order: isSortDescending ? 'desc' : 'asc',
    };
    if (docTypeId) params['doc_type_id'] = docTypeId;
    if (subTypeId) params['doc_subtype_id'] = subTypeId;
    if (activeTagId) params['tag_type_id'] = activeTagId;
    if (action) params['action'] = action;
    if (searchTerm) {
      delete params['sort_by'];
      delete params['sort_order'];
      params['search_term'] = searchTerm;
    }
    if (!dateRange) return;
    if (dateRange) {
      params['start_date'] = dateRange[0].toISOString();
      params['end_date'] = dateRange[1].toISOString();
    }
    if (actorId) params['actor_id'] = actorId;
    if (actorIdFilterMode) params['actor_id_filter_mode'] = actorIdFilterMode;

    const b = await getUserToken();
    if (unsubHistorical) unsubHistorical();
    if (unsubDocumentList) unsubDocumentList();

    const res = await axios.get(`${import.meta.env.VITE_PAPERBOX_ANALYTICS_URL}/search/${inboxId}`, {
      params: params,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: 'Bearer ' + b,
      },
    });
    dispatch(inboxSlice.actions.setHistoricalDocumentCount(res.data.total));
    const ids = res.data.list;
    const filters: any[] = [where('document_id', 'in', ids)];
    if (!searchTerm) {
      if (fsSortMap[sortByFiltered])
        filters.push(orderBy(fsSortMap[sortByFiltered], isSortDescending ? 'desc' : 'asc'));
    }
    if (ids.length > 0) {
      const ref = collection(db, `tenants/${tenantId}/documents`).withConverter(documentConverter);
      const docListQuery = query(ref, ...filters);
      await getDocs(docListQuery).then((data) => {
        let mappedList = data.docs
          .map((doc) => {
            return { ...doc.data(), id: doc.id };
          })
          .filter((e) => e.processed === true);
        if (searchTerm) {
          // Sort mappedlist so the order is the same as the list of ids
          mappedList = mappedList.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
        }

        dispatch(inboxSlice.actions.setDocumentsLoading(false));
        dispatch(inboxSlice.actions.setCurrentDocumentList(mappedList));
      });
    } else {
      dispatch(inboxSlice.actions.setDocumentsLoading(false));
      dispatch(inboxSlice.actions.setCurrentDocumentList([]));
    }
  };

export const getDocumentList =
  (inboxId: string): AppThunk =>
  (dispatch, getState) => {
    const { docTypeId, subTypeId, navDirection, pageSize, sortBy, isSortDescending, activeTagId } =
      getState().inbox.documentListOptions;
    const { firstDocumentId, lastDocumentId } = getState().inbox.documentListState;
    const docCount = getActiveCountSelector(getState());
    const pageCount = Math.ceil(docCount / pageSize);

    if (!inboxId) return;
    if (unsubHistorical) unsubHistorical();
    if (unsubDocumentList) unsubDocumentList();
    const tenantId = getState().tenant.tenantId;

    const filters = [];

    const ref = collection(db, `tenants/${tenantId}/documents`).withConverter(documentConverter);
    filters.push(where('inbox_id', '==', inboxId.toString()));

    if (docTypeId) {
      filters.push(where('doc_type_id', '==', docTypeId.toString()));
    }
    if (subTypeId) {
      filters.push(where('doc_subtype_id', '==', subTypeId.toString()));
    }
    if (activeTagId) {
      if (activeTagId === '@NO_TAG') {
        filters.push(where('tag_type_id', '==', null));
      } else {
        filters.push(where('tag_type_id', '==', activeTagId));
      }
    }
    filters.push(where('active', '==', true), where('processed', '==', true));

    if (navDirection) {
      if (navDirection === 'forward' && lastDocumentId) {
        if (getState().inbox.currentPageIndex === pageCount - 1) {
          const remainder = docCount - (pageCount - 1) * pageSize;
          filters.push(
            orderBy(camelToSnakeCase(sortBy), isSortDescending ? 'desc' : 'asc'),
            limitToLast(remainder)
          );
        } else {
          filters.push(
            orderBy(camelToSnakeCase(sortBy), isSortDescending ? 'desc' : 'asc'),
            startAfter(lastDocumentId),
            limit(pageSize)
          );
        }
      } else if (navDirection === 'back' && firstDocumentId) {
        if (getState().inbox.currentPageIndex === 0) {
          filters.push(orderBy(camelToSnakeCase(sortBy), isSortDescending ? 'desc' : 'asc'), limit(pageSize));
        } else {
          filters.push(
            orderBy(camelToSnakeCase(sortBy), isSortDescending ? 'desc' : 'asc'),
            endAt(firstDocumentId),
            limitToLast(pageSize)
          );
        }
      }
    } else {
      filters.push(orderBy(camelToSnakeCase(sortBy), isSortDescending ? 'desc' : 'asc'), limit(pageSize));
    }
    const docListQuery = query(ref, ...filters);
    const unSubscribe = onSnapshot(
      docListQuery,
      { includeMetadataChanges: false },
      (data) => {
        const existingList = getState().inbox.currentDocumentList;
        const documentList = data.docs.map((doc) => ({
          id: doc.id,
          document: doc.data(),
        }));
        const mappedList: DocumentDetails[] = [];
        documentList.forEach(({ id, document }) => {
          const existingDoc = existingList?.find((e) => e.id === id);
          const doc = camelcaseKeys(document, { deep: true }) as DocumentDetails;
          if (document['notes']) {
            doc.notes = Object.values(document['notes']);
          }
          if (existingDoc) doc.isChecked = existingDoc.isChecked;
          mappedList.push(doc);
        });

        dispatch(inboxSlice.actions.setCurrentDocumentList(mappedList));
        dispatch(
          inboxSlice.actions.setDocumentListState({
            firstDocumentId: data.docs[0],
            lastDocumentId: data.docs.at(-1),
          })
        );

        dispatch(inboxSlice.actions.setDocumentsLoading(false));
      },
      (err) => {
        console.log(err);
        dispatch(inboxSlice.actions.setDocumentsLoading(false));
      }
    );
    unsubDocumentList = unSubscribe;
    // Set Current Query as Active Subscription
    dispatch(subsSlice.actions.setDocumentListSub(unsubDocumentList));

    return unSubscribe;
  };

export const postDocument =
  (inboxId: string, form, uploadProgress, docTypeId, subTypeId, tagId) => async () => {
    /*Wrap document in formdata*/
    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      formData.append(key, value as any);
    });

    const b = await getUserToken();
    if (!b) return;
    const params = {};
    if (docTypeId) params['doc_type_id'] = docTypeId;
    if (subTypeId) params['doc_subtype_id'] = subTypeId;
    if (tagId) params['tag_type_id'] = tagId;
    const config: AxiosRequestConfig = {
      headers: { Authorization: `Bearer ${b}` },
      onUploadProgress: (progressEvent) => uploadProgress(progressEvent),
      params,
    };
    /*Post pdf to backend*/
    return await api.post(
      `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents`,

      formData,
      config
    );
  };

export const bounceDocument =
  (docId: string, inboxId: string, payload: Record<string, any>, isMutation?: boolean) =>
  async (_, getState) => {
    const userEmail = getState().user.userAccount.email;
    const list = [];
    Object.entries(payload).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        v.forEach((av) => {
          list.push({ type: k, value: `${av}` });
        });
      } else {
        list.push({ type: k, value: `${v}` });
      }
    });
    const action = { actor_email: userEmail, type: 'bounce' };
    if (list.length !== 0) action['metadata'] = list;

    const b = await getUserToken();

    const copyStructure = await getState().document.copyStructure;
    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/`;

    if (copyStructure && isMutation && copyStructure.originalDoc) {
      url += `${copyStructure.originalDoc.id}/mutations/${docId}`;
    } else {
      url += `${docId}`;
    }

    if (!b) return;
    return api.patch(
      url,
      {
        action: action,
      },
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      }
    );
  };

export const deleteDocument =
  (docId: string, inboxId: string, isMutation?: boolean) => async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;
    dispatch(documentSlice.actions.setDeletingCopyId(docId));
    const copyStructure = (await getState().document.copyStructure) as CopyStructure;
    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/`;

    if (copyStructure && isMutation && copyStructure.originalDoc?.id) {
      url += `${copyStructure.originalDoc.id}/mutations/${docId}`;
    } else {
      url += `${docId}`;
    }

    let promise;
    if (isMutation) {
      promise = api.delete(url, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + b,
        },
      });
    } else {
      const userEmail = getState().user.userAccount.email;

      promise = api.patch(
        url,
        {
          action: { actor_email: userEmail, type: 'delete' },
        },
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer ' + b,
          },
        }
      );
    }

    return promise
      .then((res: AxiosResponse) => {
        const clonedStructure = cloneDeep(copyStructure) as CopyStructure;
        if (isMutation) {
          const copyList = clonedStructure?.copyList.filter((e) => e.id !== docId);

          dispatch(
            documentSlice.actions.setCopyStructure({
              ...clonedStructure,
              copyList: copyList,
            })
          );
          if (copyList.length > 0) {
            const newSelected = copyList.at(-1);

            dispatch(documentSlice.actions.setActiveDocument(newSelected));
            dispatch(getCopy(newSelected.id, copyStructure.originalDoc.id));
            dispatch(documentSlice.actions.setDeletingCopyId(null));

            return;
          }
        }
        if (copyStructure.originalDoc) {
          dispatch(documentSlice.actions.setSelectedCopyId(copyStructure.originalDoc.id));
        }
        dispatch(documentSlice.actions.setDeletingCopyId(null));
        return res;
      })
      .catch(() => {
        dispatch(documentSlice.actions.setDeletingCopyId(null));
      });
  };

export const patchTopology =
  (docId: string, inboxId: string, updates: object, isMutation?: boolean) => async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;
    dispatch(documentSlice.actions.setIsProcessing(true));
    const copyStructure = await getState().document.copyStructure;
    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/`;
    if (copyStructure && isMutation && copyStructure.originalDoc) {
      url += `${copyStructure.originalDoc.id}/mutations/${docId}`;
    } else {
      url += `${docId}`;
    }
    return api
      .patch(
        url,
        {
          ...updates,
        },
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer ' + b,
          },
        }
      )
      .then((res) => {
        dispatch(documentSlice.actions.setIsProcessing(false));
        return res;
      });
  };

export const patchTopologyPart =
  (docId: string, inboxId: string, updates: object, partId: string, isMutation?: boolean) =>
  async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;
    dispatch(documentSlice.actions.setIsProcessing(true));
    const copyStructure = await getState().document.copyStructure;
    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/`;

    if (copyStructure && isMutation && copyStructure.originalDoc) {
      url += `${copyStructure.originalDoc.id}/mutations/${docId}`;
    } else {
      url += `${docId}`;
    }
    url += `/topology/parts/${partId}`;
    return api
      .patch(
        url,
        {
          ...updates,
        },
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer ' + b,
          },
        }
      )
      .then((res) => {
        dispatch(documentSlice.actions.setIsProcessing(false));
        return res;
      });
  };

export const patchDocument =
  (docId: string, inboxId: string, updates: object, isMutation?: boolean) => async (dispatch, getState) => {
    const b = await getUserToken();
    if (!b) return;
    dispatch(documentSlice.actions.setIsProcessing(true));

    const copyStructure = await getState().document.copyStructure;
    let url = `${import.meta.env.VITE_PAPERBOX_BACKEND_URL}/inboxes/${inboxId}/documents/`;

    if (copyStructure && isMutation && copyStructure.originalDoc) {
      url += `${copyStructure.originalDoc.id}/mutations/${docId}`;
    } else {
      url += `${docId}`;
    }
    return api
      .patch(
        url,
        {
          ...updates,
        },
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: 'Bearer ' + b,
          },
        }
      )
      .then((res) => {
        dispatch(documentSlice.actions.setIsProcessing(false));
        return res;
      });
  };

export const listenForPendingDocs = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const inboxId = getState().inbox.currentInbox.id;
  const inboxSub = getState().subs.pendingInboxDocsSub;
  if (inboxSub) inboxSub();
  const inboxRef = collection(db, `tenants/${tenantId}/documents/`);
  const inboxFilters = [];
  inboxFilters.push(where('inbox_id', '==', inboxId.toString()));
  inboxFilters.push(where('latest_workflow_run.status', '!=', 'FINISHED'));
  inboxFilters.push(limit(100));

  const inboxQuery = query(inboxRef, ...inboxFilters);

  const inboxUnsub = onSnapshot(inboxQuery, (doc) => {
    const data = doc.docs.map((e) => e.id);
    dispatch(subsSlice.actions.setPendingInboxDocsSub(inboxUnsub));
    dispatch(inboxSlice.actions.setPendingInboxDocuments(data));
  });
};

export const countResponseToType = (k: string, v: any) => {
  const newEntry: SidebarType = {
    id: k,
    count: v.count,
    name: '...',
  };
  const count = v.count;
  if (v.subtypes) {
    const entries = Object.entries(v.subtypes);
    newEntry.subTypes = entries.map(([kc, vc]) => {
      const calc = countResponseToType(kc, vc);
      return calc.entry;
    });
  }
  return { entry: newEntry, count };
};

export const getRealTimeCounters =
  (authInfo, inboxId, activeId, historical = false): AppThunk =>
  (dispatch, getState) => {
    const documentListOptions = getState().inbox.documentListOptions;
    if (inboxId == null) return;
    let params = {};
    if (historical) {
      if (documentListOptions.dateRange) {
        params = {
          start_date: documentListOptions.dateRange[0].toISOString(),
          end_date: documentListOptions.dateRange[1].toISOString(),
        };
      } else {
        params = {
          start_date: subDays(new Date(), 29),
          end_date: new Date(),
        };
      }
    }
    axios
      .get(`${import.meta.env.VITE_PAPERBOX_ANALYTICS_URL}/inboxes/${inboxId}/counters`, {
        params,
        headers: {
          authorization: 'Bearer ' + authInfo.accessToken,
        },
      })
      .then((res) => {
        if (inboxId !== activeId) return;
        const mappedCounts: SidebarType[] = [];
        let totalCount = 0;
        const data = res.data.counters;
        if (data) {
          Object.entries(data).forEach(([k, v]) => {
            const entry = countResponseToType(k, v);
            totalCount += entry.count;
            entry.entry.isPrivate = entry.entry.id.includes('@PB_DELETE');
            mappedCounts.push(entry.entry);
          });
          const existing = getState().inbox.sideBarData;
          if (!isEqual(existing, { types: mappedCounts, totalCount })) {
            dispatch(inboxSlice.actions.setSideBarData({ types: mappedCounts, totalCount }));
          }
        } else {
          dispatch(inboxSlice.actions.setSideBarData({ types: [], totalCount: 0 }));
        }
      });
  };
export const getTagCounts =
  (
    authInfo: { accessToken: string; expirationTime: number },
    inboxId: string,
    activeDocumentType: DocTypeSummary,
    historical = false
  ): AppThunk =>
  (dispatch, getState) => {
    const { searchTerm, dateRange, action } = getState().inbox.documentListOptions;

    let params = {};
    if (activeDocumentType?.docTypeId !== '') {
      params = { doc_type_id: activeDocumentType.docTypeId };
      if (activeDocumentType.subTypeId) params['doc_subtype_id'] = activeDocumentType.subTypeId;
    }
    if (historical && dateRange) {
      params = { ...params, start_date: dateRange[0].toISOString(), end_date: dateRange[1].toISOString() };
    }
    if (action) {
      params = { ...params, action };
    }
    if (searchTerm) {
      delete params['sort_by'];
      delete params['sort_order'];
      params['search_term'] = searchTerm;
    }

    if (inboxId == null) return;
    axios
      .get(`${import.meta.env.VITE_PAPERBOX_ANALYTICS_URL}/inboxes/${inboxId}/tags`, {
        params,
        headers: {
          authorization: 'Bearer ' + authInfo.accessToken,
        },
      })
      .then((res) => {
        dispatch(inboxSlice.actions.setActiveTagCounts(res.data.tags));
      });
  };

export const listenForEntityTypes = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const sub = getState().subs.entityTypesSub;
  const inboxId = getState().inbox.currentInbox.id;

  if (sub) sub();
  const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/entity_types`);
  const unsub = onSnapshot(ref, (doc) => {
    if (!doc.exists || doc.data() == null) return;
    const entityTypes = Object.entries(doc.data()).map(([key, value]) => {
      return { id: key, ...value };
    });

    const mappedEntityTypes = camelcaseKeys(entityTypes, { deep: true }) as IClientFieldType[];
    dispatch(settingsSlice.actions.setEntityTypes(mappedEntityTypes));
  });
  dispatch(subsSlice.actions.setEntityTypesSub(unsub));
  return unsub;
};

export const listenForTagTypes = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const { settings, id: inboxId } = getState().inbox.currentInbox;
  if (settings?.labelingMode) {
    const labelingTags = [
      {
        id: 'DONE',
        name: 'Done',
        color: '#91c500',
      },
      {
        id: 'TOREVIEW',
        name: 'To Review',
        color: '#FF5555',
      },
      {
        id: 'TODO',
        name: 'To Do',
        color: '#0085FF',
      },
    ];
    dispatch(settingsSlice.actions.setTagTypes(labelingTags));
    return null;
  }

  // Always a tag
  const sub = getState().subs.tagTypesSub;
  if (sub) sub();
  const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/tag_types`);
  const unsub = onSnapshot(ref, (doc) => {
    if (!doc.exists || doc.data() == null) {
      dispatch(settingsSlice.actions.setTagTypes(null));
      return;
    }
    const tagTypes = Object.entries(doc.data()).map(([key, value]) => {
      return { id: key, ...value };
    });

    const mappedTypes = camelcaseKeys(tagTypes, { deep: true }) as TagType[];
    dispatch(settingsSlice.actions.setTagTypes(mappedTypes));
  });

  dispatch(subsSlice.actions.setTagTypesSub(unsub));
  return unsub;
};

export const listenForApprovalChecks = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const sub = getState().subs.approvalChecksSub;
  const inboxId = getState().inbox.currentInbox.id;

  if (sub) sub();
  const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/approval_checks`);
  const unsub = onSnapshot(ref, (doc) => {
    const approvalChecks = [];
    if (!doc.exists || doc.data() == null) return;
    const approvalEntries = camelcaseKeys(doc.data());
    Object.entries(approvalEntries).forEach(([k, v]) => {
      const approvalCheckType = {
        id: k,
        ...(v as any),
      } as ApprovalCheckType;

      approvalChecks.push(camelcaseKeys(approvalCheckType));
    });

    dispatch(settingsSlice.actions.setApprovalCheckTypes(approvalChecks));
  });
  dispatch(subsSlice.actions.setApprovalChecksSub(unsub));
};

export const listenForMetadata = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const sub = getState().subs.metadataSub;
  const inboxId = getState().inbox.currentInbox.id;

  if (sub) sub();
  const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/metadata_keys`);
  const unsub = onSnapshot(ref, (doc) => {
    const metadataList = [];
    if (!doc.exists || doc.data() == null) return;
    Object.entries(doc.data()).forEach(([k, v]) => {
      metadataList.push(metadataRawToClient({ id: k, ...v }));
    });

    dispatch(settingsSlice.actions.setMetadataTypes(metadataList));
  });
  dispatch(subsSlice.actions.setMetadataSub(unsub));
};

export const getMasterDataTables =
  (inboxId: string): AppThunk =>
  async (dispatch, getState) => {
    const tenantId = getState().tenant.tenantId;
    const ref = collection(db, `tenants/${tenantId}/inboxes/${inboxId}/tables`);
    const tables = [];
    dispatch(inboxSlice.actions.setMasterDataMappings(null));

    await getDocs(ref).then((res) => {
      tables.push(...res.docs.map((d) => ({ id: d.id, name: d.data().name, type: d.data().type ?? '' })));
    });

    if (tables.length === 0) {
      dispatch(inboxSlice.actions.setMasterDataMappings(null));
      return;
    }

    for (const { id: tableId, type } of tables) {
      const tableSnapshots = cloneDeep(getState().subs.masterdataVersionSubs);
      if (tableSnapshots[tableId]) tableSnapshots[tableId]();

      const ref = collection(db, `tenants/${tenantId}/inboxes/${inboxId}/tables/${tableId}/versions`);
      const filters = [where('status', '==', 'ready'), orderBy('ingestion_time', 'desc'), limit(1)];

      const docListQuery = query(ref, ...filters);
      tableSnapshots[tableId] = onSnapshot(docListQuery, (res) => {
        if (res.empty) {
          return;
        }
        const versionData = camelcaseKeys(res.docs[0].data(), { deep: true });
        const existingMappings = getState().inbox.masterDataMappings;
        const mappedList: any = cloneDeep(existingMappings) ?? {};
        const currentTable = tables.find((t) => t.id === tableId);
        if (versionData['mapping']) {
          mappedList[tableId] = {
            ...mappedList[tableId],
            name: currentTable?.name ?? '',
            type: type,
            mapping: Object.entries(versionData['mapping'] as Record<string, any>).map(([k, v]) => ({
              id: k,
              ...v,
            })),
          };
        }
        if (versionData['analytics']) {
          mappedList[tableId] = {
            ...mappedList[tableId],
            analytics: Object.entries(versionData['analytics'] as Record<string, any>).map(([k, v]) => ({
              tableId: k,
              ...v,
            })) as MasterDataAnalytics[],
          };
        }

        dispatch(inboxSlice.actions.setMasterDataMappings(mappedList));
        dispatch(subsSlice.actions.setMasterdataVersionSubs(tableSnapshots));
      });
    }
  };

export const listenForActionTypes = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const sub = getState().subs.actionTypeSub;
  const inboxId = getState().inbox.currentInbox.id;

  if (sub) sub();
  const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/action_metadata_keys`);
  const unsub = onSnapshot(ref, (doc) => {
    let actionTypeList = [];
    if (!doc.exists || doc.data() == null) return;
    Object.entries(doc.data()).forEach(([k, v]) => {
      const actionType = {
        id: k,
        ...camelcaseKeys(v),
      } as ActionType;
      if (actionType['options']) {
        let options = Object.entries(actionType['options'])
          .map(([k, v]) => {
            return {
              id: k,
              ...(camelcaseKeys(v) as any),
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
        options = options.filter((st) => !st.isArchived);
        actionType.options = options;
      }
      actionTypeList.push(actionType);
    });
    actionTypeList = actionTypeList.filter((st) => !st.isArchived);

    // Define a sorting order
    const sortOrder = ['choice', 'multi-choice', 'text', 'boolean'];
    // Sort the list based on the defined order
    actionTypeList.sort((a, b) => {
      return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
    });

    dispatch(settingsSlice.actions.setActionTypes(actionTypeList));
  });
  dispatch(subsSlice.actions.setActionTypesSub(unsub));
};
export const listenForDocTypes = (): AppThunk => (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const sub = getState().subs.docTypesSub;
  const inboxId = getState().inbox.currentInbox.id;

  if (sub) {
    sub();
  }

  const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/doc_types`);
  const unsub = onSnapshot(ref, (doc) => {
    let categories;
    const data = doc.data();
    const mappedDocuments = [];
    if (data === undefined) return;

    const typeMap = {};
    const reverseMap = {};
    const localSettings = [];

    for (const [id, value] of Object.entries(data)) {
      if (value['categories'] && value['categories'].length) {
        categories = value['categories'].map((v) => {
          return {
            id: v.id,
            entityTypes: v['entity_types'],
            name: v['name'],
          };
        });
      } else {
        categories = null;
      }
      const setting: {
        docTypeId: string;
        categories?: DocTypeCategory[];
        settings: DocTypeSettings;
      } = camelcaseKeys(
        {
          docTypeId: id,
          categories: categories ? categories : null,
          settings: {
            isPrivate: id.includes('@PB_DELETE'),
            entityTypes: value['entity_types'],
            docType: { id: id, name: value['name'] },
            metadataKeys: value['metadata_keys'],
            ageThreshold: value['age_threshold'],
            approvalThreshold: value['approval_threshold'],
            entityThreshold: value['entity_threshold'] ?? 0,
            ocrThreshold: value['ocr_threshold'] ?? 0,
          },
        },
        { deep: true }
      );

      // To handle legacy string only entity_type arrays
      if (typeof value['entity_types'][0] === 'string') {
        setting.settings.entityTypes = value['entity_types'].map((et) => ({
          id: et,
          maxOccurrences: null,
          minOccurrences: null,
        }));
      }
      if (typeof value['metadata_keys'][0] === 'string') {
        setting.settings.metadataKeys = value['metadata_keys'].map((et) => ({
          id: et,
        }));
      }

      localSettings.push(setting);

      const type: SidebarType = {
        id: id,
        count: value['count'],
        name: value['name'],
        isPrivate: id.includes('@PB_DELETE'),
        isArchived: value['is_archived'],
        isFixed: value['is_fixed'] ?? false,
        subTypes: [],
        topologyType: value['topology_type'],
      };
      if (!type.name) return;

      typeMap[type.id] = type.name;
      reverseMap[type.name] = type.id;

      if (value['subtypes']) {
        for (const [subId, subValue] of Object.entries(value['subtypes'])) {
          const subType: SidebarType = {
            id: subId,
            name: subValue['name'],
            count: subValue['count'],
            isArchived: subValue['is_archived'],
          };
          if (!subType.name) return;
          type.subTypes.push(subType);
          typeMap[`${type.id}-${subType.id}`] = subType.name;
          reverseMap[subType.name] = `${type.id}-${subType.id}`;
        }
        type.subTypes.sort((a, b) => a.name.localeCompare(b.name));
      }
      mappedDocuments.push(type);
    }
    mappedDocuments.sort((a, b) => a.name.localeCompare(b.name));

    dispatch(
      inboxSlice.actions.setTypeMap({
        normal: new Map(Object.entries(typeMap)),
        reverse: new Map(Object.entries(reverseMap)),
      })
    );
    dispatch(settingsSlice.actions.setDocTypeSettings(localSettings));
    dispatch(inboxSlice.actions.setDocumentCounts(mappedDocuments));

    return unsub;
  });
  dispatch(subsSlice.actions.setDocTypesSub(unsub));

  return unsub;
};

export default inboxSlice.reducer;
