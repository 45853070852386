import AdminItemRow from '@components/admin/components/AdminItemRow.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import { IClientBounceField } from '@shared/helpers/converters/bouncefield.ts';
import { extendedSearch, globalFuseOptions } from '@shared/helpers/helpers.ts';
import { useModal } from '@shared/hooks/useModal.tsx';
import { useNotification } from '@shared/hooks/useNotificationBar.tsx';
import { adminSlice, deleteActionType } from '@shared/store/adminSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import p from '@shared/styles/component/admin/admin-pages/admin-page.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['name'],
};
const AdminInboxesBounceFields: React.FC = () => {
  const inboxBounceFields = useSelector((state) => state.admin.inboxActionTypes);

  const dispatch = useDispatch();
  const { showDialog } = useModal();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [fuseData, setFuseData] = useState([]);
  const [searchResults, setSearchResults] = useState<IClientBounceField[]>(null);
  const [selectedBounceFields, setSelectedBounceFields] = useState<string[]>([]);
  const fuse = new Fuse(fuseData, fuseOptions);

  const handleMatches = (input) => {
    setSearchResults(extendedSearch(input, fuse));
  };

  const handleInput = (value) => {
    if (value === '') {
      setSearchResults(inboxBounceFields);
    } else {
      handleMatches(value);
    }
  };
  const handleDelete = (setting: IClientBounceField) => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => dispatch(deleteActionType([setting.id]))}
        text={t('admin:inboxes.sections.actionTypeDelete')}
      />
    );
  };

  useEffect(() => {
    if (inboxBounceFields) {
      setSearchResults(inboxBounceFields);
      setFuseData(inboxBounceFields);
    }
  }, [inboxBounceFields]);
  useEffect(() => {
    return () => {
      dispatch(adminSlice.actions.setNewTempBounceOptions([]));
    };
  }, [dispatch]);

  const handleMultiDelete = () => {
    showDialog(
      <ConfirmationDialog
        confirmAction={() => {
          return dispatch(deleteActionType(selectedBounceFields));
        }}
        text={t('admin:inboxes.sections.actionTypeDelete')}
      />
    );
  };

  return (
    <div className={clsx(s.form_body, s.form_body_scroll)}>
      <div className={p.body_header}>
        <h2>{t('admin:inboxes.actionTypes')}</h2>
      </div>
      <p className={p.body_description}>{t('admin:inboxes.actionTypesDescription')}</p>

      <FormSection
        noStyle
        scroll
        title={t('admin:inboxes.actionTypes')}
        add={{
          onClick: () => navigate('new'),
          label: t('admin:inboxes.sections.addNew'),
        }}
        search={{
          onChange: (e) => handleInput(e),
          placeholder: t('admin:inboxes.sections.actionTypeSearch'),
        }}
        select={{
          handleDelete: handleMultiDelete,
          allValues: inboxBounceFields?.map((e) => e.id) ?? [],
          selectedValues: selectedBounceFields,
          setSelectedValues: setSelectedBounceFields,
        }}
      >
        <div className={clsx(s.row_list)}>
          {searchResults &&
            searchResults.map((setting, index) => {
              const isChecked = selectedBounceFields.findIndex((e) => e === setting.id) !== -1;
              return (
                <AdminItemRow
                  animationSettings={{
                    enabled: true,
                    delay: 50 + index * 25,
                  }}
                  isChecked={isChecked}
                  setIsChecked={(value) => {
                    if (value) {
                      setSelectedBounceFields([...selectedBounceFields, setting.id]);
                    } else {
                      setSelectedBounceFields(selectedBounceFields.filter((e) => e !== setting.id));
                    }
                  }}
                  handleDelete={() => handleDelete(setting)}
                  handleCopy={() => {
                    navigator.clipboard.writeText(setting.id);
                    showNotification(t('home:notifications.copied'), 'success');
                  }}
                  handleNav={() => {
                    navigate(setting.id);
                  }}
                  key={'bounceField' + setting.id}
                  actionType={setting}
                  title={setting.name}
                />
              );
            })}
          {searchResults?.length === 0 && (
            <div className={s.no_results}>{t('admin:inboxes.sections.noActionTypeFound')}</div>
          )}
        </div>
      </FormSection>
    </div>
  );
};
export default AdminInboxesBounceFields;
